<template>
    <div class="container">
        <div class="body">
            <div class="container-fluid">
                <div class="row1">
                    <div class="col-md-1 section-left2">
                    </div>
                    <div class="col-md-10 section-center2">
                        <div class="button-group">
                        </div>
                    </div>
                    <div class="row2">
                        <div class="button-container">
                        </div>
                    </div>
                    <div class="payslip-view">
                        <div v-if="loading" class="spinner-overlay">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div v-if="payslipData" class="payslip-container">
                            <div>
                                <div>
                                    <div class="payslip">
                                        <div class="title">
                                            <p>{{ payslipData.project }} PAYSLIP <span class="monthyear">{{ payslip.month }} {{ payslip.year }}</span></p>
                                            <div class="ref"><span class="code">REF CODE </span> {{ payslipData.refCode }}</div>
                                        </div>
                                        <div class="header">
                                            <div class="containe">
                                                <!-- Row 1 -->
                                                <div class="row row1">
                                                    <!-- Left Section -->
                                                    <div class="left-section">
                                                        <div class="profile-and-details">
                                                            <img :src=ProfilePhoto alt="Profile Picture"
                                                                class="profile-pic">
                                                            <div class="details">
                                                                <div class="detail-item"><span>NAMES</span></div>
                                                                <div class="detail-item"><span>GRADE
                                                                        LEVEL/STEP</span></div>
                                                                <div class="detail-item"><span>DESIGNATION</span>
                                                                </div>
                                                                <div class="detail-item"><span>MDA</span></div>
                                                                <div class="detail-item"><span>REMAINING DAYS IN
                                                                        SERVICE</span>
                                                                </div>
                                                            </div>
                                                            <div class="details2">
                                                                <div class="detail2-item">{{ payslipData.lastName
                                                                    }}
                                                                    {{ payslipData.firstName }}
                                                                    {{payslipData.middleName}}</div>
                                                                <div class="detail2-item">{{
                                                                    payslipData.gradeLevelStep
                                                                    }}</div>
                                                                <div class="detail2-item">{{
                                                                    payslipData.designation
                                                                    }}
                                                                </div>
                                                                <div class="detail2-item">{{ payslipData.mda }}
                                                                </div>
                                                                <div class="detail2-item">
                                                                    {{payslipData.remainingDaysInService
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Right Section -->
                                                    <div class="right-section">
                                                        <div class="qr-container">
                                                            <img :src="payslipData.barcode" alt="QR Code"
                                                                class="qr-code" style="">
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Row 2 -->
                                                <div class="row row22">
                                                    <div class="left-section">
                                                        <div class="profile-and-details">
                                                            <div class="bottom-details">
                                                                <div class="line"><span>DEPARTMENT</span></div>
                                                                <div class="line"><span>SALARY STRUCTURE</span>
                                                                </div>
                                                                <div class="line"><span>VERIFICATION NUMBER</span>
                                                                </div>
                                                            </div>
                                                            <div class="bottom-details2">
                                                                <div class="line2">{{ payslipData.department }}
                                                                </div>
                                                                <div class="line2">{{payslipData.salaryStructure
                                                                    }}
                                                                </div>
                                                                <div class="line2">{{ payslipData.verificationNumber }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="link1">
                                                            Visit link to verify payslip
                                                            <strong style="color:black"
                                                                :href="payslipData.verificationLink">{{payslipData.link
                                                                }}</strong>
                                                        </div>
                                                    </div>
                                                    <div class="right-section">
                                                        <div class="profile-and-details">
                                                            <div class="bank-details">
                                                                <div class="detail-bank">BVN</div>
                                                                <div class="detail-bank">BANK</div>
                                                                <div class="detail-bank">ACCOUNT NUMBER</div>
                                                            </div>
                                                            <div class="bank-details2">
                                                                <div class="detail-bank2">{{ payslipData.bvn }}
                                                                </div>
                                                                <div class="detail-bank2">{{payslipData.salarybank }}
                                                                </div>
                                                                <div class="detail-bank2">{{ payslipData.salaryaccount
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="date">
                                                            <span class="head">Date of Retirement:</span> {{
                                                            payslipData.retirement }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="info">
                                            <table>
                                                <tr class="table-header">
                                                    <th>SUBJECT</th>
                                                    <th>DESCRIPTION</th>
                                                    <th>TOTAL</th>
                                                </tr>
                                                <tr>
                                                    <td class="subject">BASIC SALARY</td>
                                                    <td></td>
                                                    <td>{{ payslipData.basicSalary }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="subject">ALLOWANCES</td>
                                                    <td>
                                                        <div class="horizontal-wrap">
                                                            <div v-for="(allowance, index) in payslipData.allowances"
                                                                :key="index">
                                                                <div v-for="[key, value] in Object.entries(allowance)"
                                                                    :key="key" class="inline-item">
                                                                    <div v-if="key !== '_id'">
                                                                        {{ key }}: {{ value }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{{ payslipData.totalallowances }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="subject">MONTHLY GROSS</td>
                                                    <td></td>
                                                    <td>{{ payslipData.monthlyGross }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="subject">DEDUCTIONS</td>
                                                    <td>
                                                        <div class="horizontal-wrap">
                                                            <div v-for="(deduction, index) in payslipData.deductions"
                                                                :key="index">
                                                                <div v-for="[key, value] in Object.entries(deduction)"
                                                                    :key="key" class="inline-item">
                                                                    <div v-if="key !== '_id'">
                                                                        {{ key }}: {{ value }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{{ payslipData.totaldeductions }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="subject">OVERPAYMENT</td>
                                                    <td></td>
                                                    <td>{{ payslipData.overpayment }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="net-pay">NET PAY</td>
                                                    <td></td>
                                                    <td class="net-pay2">{{ payslipData.netPay }}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="error" class="error-message">
                            <p>{{ error }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { useRoute } from 'vue-router';
import axios from 'axios';
import { ref, onMounted, computed } from 'vue';

export default ({
    name: 'PayslipView',

    setup() {
        const data = ref({});
        const payslip = ref(null);
        const payslipData = ref({
            lastName: "",
            firstName: "",
            gradeLevelStep: "",
            middleName: "",
            mda: "",
            designation: "",
            remainingDaysInService: "",
            department: "",
            salaryStructure: "",
            verificationNumber: "",
            verificationLink: "",
            link: "",
            bvn: "",
            salarybank: "",
            retirement: "",
            basicSalary: "",
            monthlyGross: "",
            overpayment: "",
            netPay: "",
        });
        const loading = ref(true);
        const error = ref(null);
        const route = useRoute()
        const dataString = localStorage.getItem('staffData');
        if (dataString) {
            data.value = JSON.parse(dataString);
            // console.log('staffData:', data.value);
            // console.log(data.value.access_token);
        }

        const fetchPayslip = async (refCode) => {
            try {
                const apiEndpoint = `${process.env.VUE_APP_API_ENDPOINT}/payslip/verify/${refCode}`.replace(
                    /\s+/g,
                    ""
                );
                const response = await axios.get(apiEndpoint,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.VUE_APP_API_KEY,
                        'authorization': data.value.access_token,
                    },
                });
                Object.assign(payslipData.value, response.data);
                console.log("PAYSLIP DATA", payslipData.value);

            } catch (err) {
                error.value = 'Failed to load payslip data.';
            } finally {
                loading.value = false;
            }
        };
        const ProfilePhoto = computed(() => {
            try {
                return require(`@/assets/images/${payslipData.value.image}`);
            } catch (e) {
                return require('@/assets/images/avatar.png');
            }
        });

        onMounted(() => {
            // console.log(route.params.refCode)
            const refCode = route.params.refCode;
            if (refCode) {
                fetchPayslip(refCode);
            } else {
                error.value = 'No reference code provided.';
                loading.value = false;
            }
        });

        return {
        payslip,
        loading,
        error,
        data,
        payslipData,
        ProfilePhoto,
        };
    }
});
</script>

<style scoped>

.body {
    background-repeat: no-repeat;
    background-position: 50% 90%;
    width: 100%;
    background-attachment: fixed;
}

.button-container {
    margin-top: 0px;
    margin-right: 0px;
}

.container {
    background-color: #e9e8e8;
    /* color: #002D72; */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow-x: scroll;

}

li {
    margin-top: 40px;
}

.main-content {
    padding: 20px;
}

.description {
    font-size: 1.2em;
    color: #002D72;
}

.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.row1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    margin-top: 40px;
    margin-left: 40px;
    padding-bottom: 0px;
}

.row22 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    margin-top: 30px;
}

.payslip-container {
    border: 1px solid white;
    background-color: white;
    padding: 8px;
    margin-top: 3px;
}

.payslip {
    margin: 10px auto;
    padding: 8px;
    border: 1px solid #5c5c5c;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-size: 10px;
    font-weight: bold;
    line-height: 12.55px;
    color: #034D7F;
    width: 700px;
    min-height: 405px;
    margin-top: 30px;
}

.title {
    margin-bottom: 5px;
    border-bottom: 1px solid #48484874;
    padding-bottom: 0px;
    text-align: left;
}

.title p {
    font-size: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: #484848;
}

.title .monthyear {
    font-weight: 60px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: black;
}

.ref {
    text-align: right;
    margin-top: -10px;
    color: #000000;
    /* font-size: 13px; */
    /* margin-right: 30px; */
}

.code {
    color: #6464647e;
}

.header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0px;
    margin-bottom: 0px;
    gap: 10px;
}

/* .container {
    width: 90%;
    margin: 20px auto;
    padding: 10px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

.row {
    display: flex;
    padding: 10px 0;
    padding-top: 0px;
    /* border-bottom: 1px solid #ccc; */
}

.row:last-child {
    border-bottom: none;
}

.left-section {
    width: 90%;
    /* height: 87px; */
    /* gap: 8px; */

}

.right-section {
    width: 10%;
    /* text-align: center; */
}

.qr-container {
    margin-top: -19px;
    display: flex;
    justify-content: flex-end;
}

.qr-code {
    border-radius: 4px;
    border: 1px solid #034D7F;
    width: 95px;
    height: 100px;
    padding: 5px;
    margin-top: -85px;
    margin-right: -290px;
}

.profile-and-details {
    display: flex;
    margin-top: -40px;
    /* height:90px */
}


.profile-pic {
    border-radius: 4px;
    width: 85px;
    height: 100px;
    margin-top: 0px;
    margin-left: -75px;
    margin-right: 10px;
    border: 1px solid #7e7f7f70;
    padding: 4px;
    /* padding-right: 40px; */
    object-fit: cover;
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 5px;
}

.details2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #2c2b2bd7;
    background-color: rgba(234, 227, 219, 0.477);
    -webkit-print-color-adjust: exact;
    /* Ensures colors (including background images) are preserved during printing */
    print-color-adjust: exact;
    /* Same as above for non-WebKit browsers */

}

.details .detail-item {
    display: inline-block;
    border: 1px solid #034D7F;
    padding: 2px;
    border-radius: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
    background-color: white;
    padding-right: 5px;
}

.details2 .detail2-item {
    display: inline-block;
    border: 1px solid white;
    padding: 2px;
    border-radius: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
    padding-right: 215px;
}

.bottom-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 2px;
    margin-left: -4px;
    margin-top: 2px;
}

.bottom-details2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #2c2b2bd7;
    background-color: rgba(234, 227, 219, 0.477);
    -webkit-print-color-adjust: exact;
    /* Ensures colors (including background images) are preserved during printing */
    print-color-adjust: exact;
    /* Same as above for non-WebKit browsers */

}

.bottom-details .line {
    display: inline-block;
    border: 1px solid #034D7F;
    padding: 2px;
    border-radius: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
    background-color: white;
    padding-right: 0px;
}

.bottom-details2 .line2 {
    display: inline-block;
    border: 1px solid white;
    padding: 2px;
    border-radius: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
    padding-right: 38px;
}



.link1 {
    color: #6d6c6ca6;
    /* display: flex;
    text-align: left; */
    margin-top: 5px;
    margin-bottom: 0px;
    margin-left: -3px;
    display: inline;
    /* Ensures the content stays in line */
    white-space: nowrap;
    /* Prevents text from wrapping to a new line */
}

.bank-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 2px;
    margin-left: 45px;
    margin-top: 5px;
}

.bank-details2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #2c2b2bd7;
    background-color: rgba(234, 227, 219, 0.477);
    -webkit-print-color-adjust: exact;
    /* Ensures colors (including background images) are preserved during printing */
    print-color-adjust: exact;
    /* Same as above for non-WebKit browsers */

}

.row2 {
    margin-right: -330px;
}

.bank-details .detail-bank {
    display: inline-block;
    border: 1px solid #034D7F;
    padding: 2px;
    border-radius: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
    background-color: white;
    padding-right: -5px;
}

.bank-details2 .detail-bank2 {
    display: inline-block;
    border: 1px solid white;
    padding: 2px;
    border-radius: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
    padding-right: 20px;
}

.date {
    text-align: right;
    color: black;
    margin-top: 3px;
    margin-bottom: 0px;
}

.date .head {
    color: #6d6c6ca6;
}

.row22 .left-section,
.row22 .right-section {
    width: 50%;

}

.row22 .left-section .detail-item,
.row22 .right-section .detail-item {
    margin-bottom: 0px;
}

.info {
    padding: 5px;
    margin-top: -13px;
    border-radius: 8px;
    padding: 8px;

}

.info table {
    width: 665px;
    height: 180px;
    border-collapse: collapse;
    margin: 0;


}

.info th,
.info td {
    padding: 2px;
    text-align: left;
    border: 2px solid #2c2b2bd7;
    /* border-radius: 4px; */
    color: #2c2b2bd7;
}

.info th {
    background-color: #000000;
    color: white;
    text-align: center;
}

.subject {
    background-color: #d5eaf8;
    color: black;
}

.info .net-pay {
    color: #f5f7f7;
    font-size: 12px;
    /* font-weight: bold; */
    background-color: #0e0e0e;
}

.info .net-pay2 {
    color: #000000;
    font-size: 12px;
    /* font-weight: bold; */
    /* background-color: #0e0e0e; */
}

.horizontal-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    color: #6d6c6ca6;

}

.inline-item {
    display: inline-block;
    border: 1px solid #6d6c6ca6;
    padding: 2px;
    border-radius: 4px;
    white-space: nowrap;
    margin-right: 8px;
    /* Adds space to the right of each key-value pair */
    margin-bottom: 3px;
}

.table-header {
    text-align: center;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    background-color: #e9e8e8;
}

.pagination-controls button {
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    border: 1px solid #3937d5;
    background-color: #f8f9fa;
    cursor: pointer;
    border-radius: 10px;
}

.pagination-controls button:hover {
    background-color: #03419e;
    color: whitesmoke;
}


.pagination-controls button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.pagination-controls span {
    align-self: center;
    margin: 0 1rem;
}

 @media (max-width: 800px) {
     .payslip-container {
         overflow-x: auto;
     }

     .payslip {
         width: 700px;
         /* Fixed width */
         margin: 0 auto;
         /* Ensure horizontal scrolling */
         display: inline-block;
     }
 }
</style>
