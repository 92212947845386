<template>
    <div class="login-page">
        <header class="header">
            <div class="header-button">
                <button class="head_button" type="submit" @click="closePage">
                    <i class="bi bi-x-circle"> Close</i>
                </button>
            </div>
        </header>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <img class="bubeb-image" src="../assets/images/benue_subeb.png" alt="Logo">
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header text-center">
                            <img class="key-images" src="../assets/images/3dicons.png" alt="Key Icon">
                            <div class="card-body">
                                <form @submit.prevent="submitForm">
                                    <div class="form-group">
                                        <h2>Create Your Portal PIN</h2>
                                        <h5>You will need this PIN anytime you wish to access this portal </h5>
                                    </div>
                                    <div class="pin-input-container">
                                        <div class="pin-inputs">
                                            <input v-for="(value, index) in createPin" :key="'create' + index"
                                                :type="showPin ? 'text' : 'password'" maxlength="1" class="pin-input"
                                                v-model="createPinDisplay[index]"
                                                @input="event => handleInput(event, index, 'create')"
                                                @keydown="event => handleKeyDown(event, index, 'create')"
                                                :ref="el => createInputRefs[index] = el" />
                                        </div>
                                        <h5>Enter Your PIN</h5>
                                        <div class="pin-inputs">
                                            <input v-for="(value, index) in confirmPin" :key="'confirm' + index"
                                                :type="showPin ? 'text' : 'password'" maxlength="1" class="pin-input"
                                                v-model="confirmPinDisplay[index]"
                                                @input="event => handleInput(event, index, 'confirm')"
                                                @keydown="event => handleKeyDown(event, index, 'confirm')"
                                                :ref="el => confirmInputRefs[index] = el"
                                                :class="{ 'mismatch': !pinMatch && allFieldsFilled }" />
                                        </div>
                                        <h5>Re-Enter Your PIN</h5>
                                        <p v-if="!pinMatch && allFieldsFilled" class="error-message">PINs do not match
                                        </p>
                                    </div>
                                    <div class="mb-3">
                                        <button class="btn btn-primary submit-button" type="submit"
                                            :disabled="!canSubmit || loading">
                                            <span v-if="loading" class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            <span v-if="loading"> Loading...</span>
                                            <span v-else>Proceed</span></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import Swal from 'sweetalert2';
import { useRouter } from 'vue-router';
import axios from 'axios';
export default {
    name: 'CreatePin',
    setup() {

        // Initialize arrays to store PIN input values and display values
        const createPin = ref(['', '', '', '']);
        const createPinDisplay = ref(['', '', '', '']);
        const confirmPin = ref(['', '', '', '']);
        const confirmPinDisplay = ref(['', '', '', '']);
        const createInputRefs = ref([]);
        const confirmInputRefs = ref([]);
        const showPin = ref(false);
        const pinMatch = ref(true);
        const loading = ref(false); // New state to track loading status
        const router = useRouter();
        const verification_numba = localStorage.getItem('verification_number') || '';

        const focusNext = (index, type) => {
            if (type === 'create' && index < 3) {
                createInputRefs.value[index + 1].focus();
            } else if (type === 'confirm' && index < 3) {
                confirmInputRefs.value[index + 1].focus();
            }
        };

        const handleInput = (event, index, type) => {
            const value = event.target.value;
            if (type === 'create') {
                createPin.value[index] = value;
                createPinDisplay.value[index] = value;
            } else {
                confirmPin.value[index] = value;
                confirmPinDisplay.value[index] = value;
            }

            // Mask the input value after a delay
            setTimeout(() => {
                if (type === 'create') {
                    createPinDisplay.value[index] = '*';
                } else {
                    confirmPinDisplay.value[index] = '*';
                }
            }, 500); // Show the input value for 500ms before masking it

            // Move focus to the next input field
            focusNext(index, type);
        };
        const handleKeyDown = (event, index, type) => {
            if (event.key === 'Backspace') {
                if (type === 'create') {
                    if (createPinDisplay.value[index] === '') {
                        if (index > 0) {
                            createPin.value[index - 1] = '';
                            createPinDisplay.value[index - 1] = '';
                            createInputRefs.value[index - 1].focus();
                        }
                    } else {
                        createPin.value[index] = '';
                        createPinDisplay.value[index] = '';
                    }
                } else {
                    if (confirmPinDisplay.value[index] === '') {
                        if (index > 0) {
                            confirmPin.value[index - 1] = '';
                            confirmPinDisplay.value[index - 1] = '';
                            confirmInputRefs.value[index - 1].focus();
                        }
                    } else {
                        confirmPin.value[index] = '';
                        confirmPinDisplay.value[index] = '';
                    }
                }
            }

        };
        watch(confirmPin, () => {
            pinMatch.value = createPin.value.join('') === confirmPin.value.join('');
        }, { deep: true });

        const isPinMatch = computed(() => {
            return createPin.value.join('') === confirmPin.value.join('');
        });

        const allFieldsFilled = computed(() => {
            return createPin.value.every(pin => pin !== '') && confirmPin.value.every(pin => pin !== '');
        });

        const canSubmit = computed(() => {
            return isPinMatch.value && allFieldsFilled.value;
        });

        const submitForm = async () => {
            loading.value = true; // Start loading
            try {
                if (createPin.value.join('') === confirmPin.value.join('')) {
                    const apiEndpoint = `${process.env.VUE_APP_API_ENDPOINT}/auth/create_pin`.replace(
                        /\s+/g,
                    ""
                );
                    const response = await axios.post(apiEndpoint, {

                        pin: createPin.value.join(''),
                        confirmPin: confirmPin.value.join(''),
                        verification_number: verification_numba,

                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': process.env.VUE_APP_API_KEY,
                        },
                        // body: JSON.stringify(data),
                    });
                    localStorage.setItem('verification_number', verification_numba);
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message
                    });
                    
                    router.push({ name: 'StaffLogin' });
                    // Handle successful response here
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'PINs do not match. Please try again.',
                    });
                    return;
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.response.data.message,
                });
            } finally {
                loading.value = false; // End loading
            }
        };

        const closePage = () => {
            router.push({ name: 'Home' }).then(() => {
                window.location.reload();
            });
        };

        return {
            createPin,
            confirmPin,
            createPinDisplay,
            confirmPinDisplay,
            createInputRefs,
            confirmInputRefs,
            handleInput,
            handleKeyDown,
            submitForm,
            showPin,
            pinMatch,
            isPinMatch,
            allFieldsFilled,
            canSubmit,
            closePage,
            loading, // Add loading to the return object
        };
    }
};
</script>

<style scoped>
.head_button {
    background-color: #093c72;
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 35px;
    color: white;
    cursor: pointer;
    border: none;
    position: absolute;
    top: 10px;
    right: 20px;
    /* border-radius: 80px; */
}

.login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: rgb(44, 153, 224);
    background: linear-gradient(90deg, rgba(44, 153, 224, 1) 0%, rgba(222, 222, 227, 1) 42%, rgba(242, 242, 242, 1) 100%);
}

.card {
    align-items: right;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    background-color: #e9e8e8;
    /* position: absolute; */
    /* right: 40px; */
}

.card-header {
    background-color: #e9e8e8;
    border-bottom: none;

}

.btn-primary {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.bubeb-image {
    height: 100%;
    width: 90%;
}

.key-images {
    height: 100px;
    width: 90px;
    border-left: 160px;
}

.pin-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pin-inputs {
    display: flex;
    gap: 10px;
}

.pin-input {
    width: 45px;
    height: 60px;
    border-radius: 40%;
    text-align: center;
    border: 1px solid #ccc;
    font-size: 18px;
}

button {
    margin-top: 20px;
}

p {
    color: red;
}

h5 {
    color: #8d8d8d;
}

.submit-button {
    width: 100%;
    /* Match the width of v-select */
    margin-top: 60px;
/* Default color for active state */
    color: white;
    /* Text color */
    border: 1px solid blue;
    /* Border color for active state */
}

/* Styles for the disabled state */
.submit-button:disabled {
    background-color: grey;
    /* Color when disabled */
    border: 1px solid grey;
    /* Border color when disabled */
    cursor: not-allowed;
    /* Change cursor to indicate disabled state */
    opacity: 0.6;
    /* Optionally, reduce opacity for a more subdued look */
}

/* Optional: Additional styles for hover and focus */
.submit-button:hover:enabled {
    background-color: darkblue;
    /* Darker blue for hover effect when enabled */
    border: 1px solid darkblue;
    /* Darker blue for border on hover */
}

@media (max-width: 540px) {
    .head_button {
        padding: 5px 10px;
        font-size: 15px;
    }
}
</style>
