<template>
    <div class="login-page">
        <header class="header">
            <div class="header-button">
                <button class="head_button" type="submit" @click="closePage">
                    <i class="bi bi-x-circle"> Close</i></button>
            </div>
        </header>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <img class="bubeb-image" src="../assets/images/benue_subeb.png">
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header text-center">
                            <img class="key-images" src="../assets/images/3dicons.png">
                            <div class="card-body">
                                <form @submit.prevent="handleSendOtp">
                                    <div class="mb-3">
                                        <input type="text" id="verification_number"
                                            v-model="formData.verification_number" class="form-control"
                                            placeholder="Enter your Verification Number" required>
                                    </div>
                                    <div class=" mb-3">
                                        <input type="text" id="account" v-model="formData.account" class="form-control"
                                            Enter placeholder="Enter Your Salary Bank Account Number">
                                    </div>
                                    <div class=" mb-3">
                                        <div class="mb-3">
                                            <v-select :options="banks" label="name" v-model="formData.bank"
                                                placeholder="Select Bank"></v-select>
                                        </div>
                                    </div>
                                    
                                    <div class="mb-3">
                                        <label class="form-label">Which Channel Do You Wish To Recieve Otp?</label>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" id="phone"
                                                name="otpdeliverychannel" value="phone" v-model="selectedOption"
                                                :disabled="isPhoneDisabled">
                                            <label class="form-check-label" for="phone">SMS</label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" id="email" value="email"
                                                name="otpdeliverychannel" v-model="selectedOption"
                                                :disabled="isEmailDisabled">
                                            <label class="form-check-label" for="email">EMAIL</label>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <button type="submit" class="btn btn-primary submit-button" :disabled="!isFormValid ||
                    loading"><span v-if="loading" class="spinner-border spinner-border-sm"
                                                role="status" aria-hidden="true"></span>
                                            <span v-if="!loading">Procced</span></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name: 'StaffReg',

    setup() {
        const router = useRouter();

        // Retrieve data from local storage
        const savedVerificationNumber = localStorage.getItem('verification_number') || '';
        const savedPhoneNumber = localStorage.getItem('phone_number') || '';
        const savedEmail = localStorage.getItem('email') || '';
        // console.log(savedPhoneNumber);
        // console.log(savedEmail);
        
        

        // Initialize form data with values from local storage
        const formData = ref({
            verification_number: savedVerificationNumber,
            account: '',
            bank: null,
        });

        // Computed properties with explicit checks
        const isPhoneDisabled = computed(() => {
            return !savedPhoneNumber || savedPhoneNumber === 'null' || savedPhoneNumber === '' || savedPhoneNumber === undefined || savedPhoneNumber === false;
        });

        const isEmailDisabled = computed(() => {
            return !savedEmail || savedEmail === 'null' || savedEmail === '' || savedEmail === undefined || savedEmail === false;
        });


        const banks = ref([
                        {
                            "name": "NIBSS",
                            "bankCode": "999",
                            "nipCode": "999999",
                            "active": true,
                            "abcSortCode": "0",
                            "isOfi": false
                        },
                        {
                            "name": "CBN",
                            "bankCode": "001",
                            "nipCode": "000028",
                            "active": true,
                            "abcSortCode": "001150000",
                            "isOfi": false
                        },
                        {
                            "name": "ACCESS BANK PLC",
                            "bankCode": "044",
                            "nipCode": "000014",
                            "active": true,
                            "abcSortCode": "044150000",
                            "isOfi": false
                        },
                        {
                            "name": "KEYSTONE BANK PLC",
                            "bankCode": "082",
                            "nipCode": "000002",
                            "active": true,
                            "abcSortCode": "082150000",
                            "isOfi": false
                        },
                        {
                            "name": "ECOBANK NIGERIA PLC",
                            "bankCode": "050",
                            "nipCode": "000010",
                            "active": true,
                            "abcSortCode": "050150000",
                            "isOfi": false
                        },
                        {
                            "name": "FIDELITY BANK PLC",
                            "bankCode": "070",
                            "nipCode": "000007",
                            "active": true,
                            "abcSortCode": "070150000",
                            "isOfi": false
                        },
                        {
                            "name": "FIRST BANK OF NIGERIA PLC",
                            "bankCode": "011",
                            "nipCode": "000016",
                            "active": true,
                            "abcSortCode": "011150000",
                            "isOfi": false
                        },
                        {
                            "name": "FIRST CITY MONUMENT BANK PLC",
                            "bankCode": "214",
                            "nipCode": "000003",
                            "active": true,
                            "abcSortCode": "214150000",
                            "isOfi": false
                        },
                        {
                            "name": "GUARANTY TRUST BANK PLC",
                            "bankCode": "058",
                            "nipCode": "000013",
                            "active": true,
                            "abcSortCode": "058150000",
                            "isOfi": false
                        },
                        {
                            "name": "STANBIC IBTC BANK PLC",
                            "bankCode": "221",
                            "nipCode": "000012",
                            "active": true,
                            "abcSortCode": "221150000",
                            "isOfi": false
                        },
                        {
                            "name": "CITI BANK",
                            "bankCode": "023",
                            "nipCode": "000009",
                            "active": true,
                            "abcSortCode": "023150000",
                            "isOfi": false
                        },
                        {
                            "name": "POLARIS BANK",
                            "bankCode": "076",
                            "nipCode": "000008",
                            "active": true,
                            "abcSortCode": "076150000",
                            "isOfi": false
                        },
                        {
                            "name": "ENTERPRISE BANK PLC",
                            "bankCode": "084",
                            "nipCode": "000019",
                            "active": true,
                            "abcSortCode": "084150000",
                            "isOfi": false
                        },
                        {
                            "name": "STANDARD CHARTERED BANK PLC",
                            "bankCode": "068",
                            "nipCode": "000021",
                            "active": true,
                            "abcSortCode": "068150000",
                            "isOfi": false
                        },
                        {
                            "name": "STERLING BANK PLC",
                            "bankCode": "232",
                            "nipCode": "000001",
                            "active": true,
                            "abcSortCode": "232150000",
                            "isOfi": false
                        },
                        {
                            "name": "UNION BANK OF NIGERIA PLC",
                            "bankCode": "032",
                            "nipCode": "000018",
                            "active": true,
                            "abcSortCode": "032150000",
                            "isOfi": false
                        },
                        {
                            "name": "UNITED BANK FOR AFRICA PLC",
                            "bankCode": "033",
                            "nipCode": "000004",
                            "active": true,
                            "abcSortCode": "033150000",
                            "isOfi": false
                        },
                        {
                            "name": "UNITY BANK PLC",
                            "bankCode": "215",
                            "nipCode": "000011",
                            "active": true,
                            "abcSortCode": "215150000",
                            "isOfi": false
                        },
                        {
                            "name": "WEMA BANK PLC",
                            "bankCode": "035",
                            "nipCode": "000017",
                            "active": true,
                            "abcSortCode": "035150000",
                            "isOfi": false
                        },
                        {
                            "name": "ZENITH INTERNATIONAL BANK PLC",
                            "bankCode": "057",
                            "nipCode": "000015",
                            "active": true,
                            "abcSortCode": "057150000",
                            "isOfi": false
                        },
                        {
                            "name": "JAIZ BANK",
                            "bankCode": "301",
                            "nipCode": "000006",
                            "active": true,
                            "abcSortCode": "301150000",
                            "isOfi": false
                        },
                        {
                            "name": "TAJ BANK",
                            "bankCode": "302",
                            "nipCode": "000026",
                            "active": true,
                            "abcSortCode": "302150000",
                            "isOfi": false
                        },
                        {
                            "name": "PROVIDUS",
                            "bankCode": "101",
                            "nipCode": "000023",
                            "active": true,
                            "abcSortCode": "101150000",
                            "isOfi": false
                        },
                        {
                            "name": "SUNTRUST",
                            "bankCode": "100",
                            "nipCode": "000022",
                            "active": true,
                            "abcSortCode": "100150000",
                            "isOfi": false
                        },
                        {
                            "name": "Rand Merchant Bank",
                            "bankCode": "502",
                            "nipCode": "000024",
                            "active": true,
                            "abcSortCode": "502150000",
                            "isOfi": false
                        },
                        {
                            "name": "TITAN TRUST BANK",
                            "bankCode": "102",
                            "nipCode": "000025",
                            "active": true,
                            "abcSortCode": "102150000",
                            "isOfi": false
                        },
                        {
                            "name": "Globus Bank",
                            "bankCode": "103",
                            "nipCode": "000027",
                            "active": true,
                            "abcSortCode": "103150000",
                            "isOfi": false
                        },
                        {
                            "name": "Lotus Bank",
                            "bankCode": "303",
                            "nipCode": "000029",
                            "active": true,
                            "abcSortCode": "303150000",
                            "isOfi": false
                        },
                        {
                            "name": "Coronation Merchant Bank",
                            "bankCode": "559",
                            "nipCode": "060001",
                            "active": true,
                            "abcSortCode": "559150000",
                            "isOfi": false
                        },
                        {
                            "name": "FBNQUEST MERCHANT BANK",
                            "bankCode": "560",
                            "nipCode": "060002",
                            "active": true,
                            "abcSortCode": "560150000",
                            "isOfi": false
                        },
                        {
                            "name": "NOVA Merchant Bank",
                            "bankCode": "561",
                            "nipCode": "060003",
                            "active": true,
                            "abcSortCode": "561150000",
                            "isOfi": false
                        },
                        {
                            "name": "FSDH",
                            "bankCode": "501",
                            "nipCode": "400001",
                            "active": true,
                            "abcSortCode": "501150000",
                            "isOfi": false
                        },
                        {
                            "name": "CARBON",
                            "bankCode": "565",
                            "nipCode": "100026",
                            "active": true,
                            "abcSortCode": "565150000",
                            "isOfi": false
                        },
                        {
                            "name": "VFD MICROFINANCE BANK",
                            "bankCode": "566",
                            "nipCode": "090110",
                            "active": true,
                            "abcSortCode": "566150000",
                            "isOfi": false
                        },
                        {
                            "name": "Paga",
                            "bankCode": "327",
                            "nipCode": "100002",
                            "active": true,
                            "abcSortCode": "327150000",
                            "isOfi": false
                        },
                        {
                            "name": "Eartholeum",
                            "bankCode": "690",
                            "nipCode": "100021",
                            "active": true,
                            "abcSortCode": "690150000",
                            "isOfi": false
                        },
                        {
                            "name": "Stanbic Mobile",
                            "bankCode": "304",
                            "nipCode": "100007",
                            "active": true,
                            "abcSortCode": "304150000",
                            "isOfi": false
                        },
                        {
                            "name": "OPAY",
                            "bankCode": "305",
                            "nipCode": "100004",
                            "active": true,
                            "abcSortCode": "305150000",
                            "isOfi": false
                        },
                        {
                            "name": "E-Tranzact",
                            "bankCode": "306",
                            "nipCode": "100006",
                            "active": true,
                            "abcSortCode": "306150000",
                            "isOfi": false
                        },
                        {
                            "name": "Ecobank Xpress Account",
                            "bankCode": "307",
                            "nipCode": "100008",
                            "active": true,
                            "abcSortCode": "307150000",
                            "isOfi": false
                        },
                        {
                            "name": "Fortis Mobile",
                            "bankCode": "308",
                            "nipCode": "100016",
                            "active": true,
                            "abcSortCode": "308150000",
                            "isOfi": false
                        },
                        {
                            "name": "FIRSTMONIE WALLET",
                            "bankCode": "309",
                            "nipCode": "100014",
                            "active": true,
                            "abcSortCode": "309150000",
                            "isOfi": false
                        },
                        {
                            "name": "Parkway",
                            "bankCode": "311",
                            "nipCode": "100003",
                            "active": true,
                            "abcSortCode": "311150000",
                            "isOfi": false
                        },
                        {
                            "name": "M-Kudi",
                            "bankCode": "313",
                            "nipCode": "100011",
                            "active": true,
                            "abcSortCode": "313150000",
                            "isOfi": false
                        },
                        {
                            "name": "FETS",
                            "bankCode": "314",
                            "nipCode": "100001",
                            "active": true,
                            "abcSortCode": "314150000",
                            "isOfi": false
                        },
                        {
                            "name": "GT Mobile Money",
                            "bankCode": "315",
                            "nipCode": "100009",
                            "active": true,
                            "abcSortCode": "315150000",
                            "isOfi": false
                        },
                        {
                            "name": "Cellulant",
                            "bankCode": "317",
                            "nipCode": "100005",
                            "active": true,
                            "abcSortCode": "317150000",
                            "isOfi": false
                        },
                        {
                            "name": "ASO Savings And Loans",
                            "bankCode": "401",
                            "nipCode": "090001",
                            "active": true,
                            "abcSortCode": "401150000",
                            "isOfi": false
                        },
                        {
                            "name": "Teasy Mobile",
                            "bankCode": "319",
                            "nipCode": "100010",
                            "active": true,
                            "abcSortCode": "319150000",
                            "isOfi": false
                        },
                        {
                            "name": "VTNetwork",
                            "bankCode": "320",
                            "nipCode": "100012",
                            "active": true,
                            "abcSortCode": "320150000",
                            "isOfi": false
                        },
                        {
                            "name": "AccessMoney",
                            "bankCode": "323",
                            "nipCode": "100013",
                            "active": true,
                            "abcSortCode": "323150000",
                            "isOfi": false
                        },
                        {
                            "name": "Hedonmark",
                            "bankCode": "324",
                            "nipCode": "100017",
                            "active": true,
                            "abcSortCode": "324150000",
                            "isOfi": false
                        },
                        {
                            "name": "Zenith Mobile",
                            "bankCode": "322",
                            "nipCode": "100018",
                            "active": true,
                            "abcSortCode": "322150000",
                            "isOfi": false
                        },
                        {
                            "name": "Fidelity Mobile",
                            "bankCode": "318",
                            "nipCode": "100019",
                            "active": true,
                            "abcSortCode": "318150000",
                            "isOfi": false
                        },
                        {
                            "name": "Jubilee Life",
                            "bankCode": "402",
                            "nipCode": "090003",
                            "active": true,
                            "abcSortCode": "402150000",
                            "isOfi": false
                        },
                        {
                            "name": "Moneybox",
                            "bankCode": "325",
                            "nipCode": "100020",
                            "active": true,
                            "abcSortCode": "325150000",
                            "isOfi": false
                        },
                        {
                            "name": "Trustbond",
                            "bankCode": "523",
                            "nipCode": "090005",
                            "active": true,
                            "abcSortCode": "523150000",
                            "isOfi": false
                        },
                        {
                            "name": "Covenant Microfinance Bank",
                            "bankCode": "551",
                            "nipCode": "070006",
                            "active": true,
                            "abcSortCode": "551150000",
                            "isOfi": false
                        },
                        {
                            "name": "NPF MFB",
                            "bankCode": "552",
                            "nipCode": "070001",
                            "active": true,
                            "abcSortCode": "552150000",
                            "isOfi": false
                        },
                        {
                            "name": "GoMoney",
                            "bankCode": "326",
                            "nipCode": "100022",
                            "active": true,
                            "abcSortCode": "326150000",
                            "isOfi": false
                        },
                        {
                            "name": "VISUAL ICT",
                            "bankCode": "328",
                            "nipCode": "100023",
                            "active": true,
                            "abcSortCode": "328150000",
                            "isOfi": false
                        },
                        {
                            "name": "SafeTrust Mortgage Bank",
                            "bankCode": "403",
                            "nipCode": "090006",
                            "active": true,
                            "abcSortCode": "403150000",
                            "isOfi": false
                        },
                        {
                            "name": "PayAttitude Online",
                            "bankCode": "329",
                            "nipCode": "110001",
                            "active": true,
                            "abcSortCode": "329150000",
                            "isOfi": false
                        },
                        {
                            "name": "Livingtrust Mortgage Bank Plc",
                            "bankCode": "606",
                            "nipCode": "070007",
                            "active": true,
                            "abcSortCode": "606150000",
                            "isOfi": false
                        },
                        {
                            "name": "IMPERIAL HOMES MORTGAGE BANK",
                            "bankCode": "415",
                            "nipCode": "100024",
                            "active": true,
                            "abcSortCode": "415150000",
                            "isOfi": false
                        },
                        {
                            "name": "PAGE FINANCIALS",
                            "bankCode": "827",
                            "nipCode": "070008",
                            "active": true,
                            "abcSortCode": "827150000",
                            "isOfi": false
                        },
                        {
                            "name": "FIRSTTRUST MORGAGES LIMITED",
                            "bankCode": "413",
                            "nipCode": "090107",
                            "active": true,
                            "abcSortCode": "413150000",
                            "isOfi": false
                        },
                        {
                            "name": "miMONEY",
                            "bankCode": "330",
                            "nipCode": "100027",
                            "active": true,
                            "abcSortCode": "330150000",
                            "isOfi": false
                        },
                        {
                            "name": "EKONDO MICROFINANCE BANK",
                            "bankCode": "847",
                            "nipCode": "090097",
                            "active": true,
                            "abcSortCode": "847150000",
                            "isOfi": false
                        },
                        {
                            "name": "FINATRUST MICROFINANCE BANK",
                            "bankCode": "608",
                            "nipCode": "090111",
                            "active": true,
                            "abcSortCode": "608150000",
                            "isOfi": false
                        },
                        {
                            "name": "SEED CAPITAL MICROFINANCE BANK",
                            "bankCode": "609",
                            "nipCode": "090112",
                            "active": true,
                            "abcSortCode": "609150000",
                            "isOfi": false
                        },
                        {
                            "name": "TCF",
                            "bankCode": "567",
                            "nipCode": "090115",
                            "active": true,
                            "abcSortCode": "567150000",
                            "isOfi": false
                        },
                        {
                            "name": "Empire Trust MFB",
                            "bankCode": "568",
                            "nipCode": "090114",
                            "active": true,
                            "abcSortCode": "568150000",
                            "isOfi": false
                        },
                        {
                            "name": "KONGAPAY",
                            "bankCode": "321",
                            "nipCode": "100025",
                            "active": true,
                            "abcSortCode": "321150000",
                            "isOfi": false
                        },
                        {
                            "name": "AG MORTGAGE BANK PLC ",
                            "bankCode": "418",
                            "nipCode": "100028",
                            "active": true,
                            "abcSortCode": "418150000",
                            "isOfi": false
                        },
                        {
                            "name": "MIcrovis MFB",
                            "bankCode": "569",
                            "nipCode": "090113",
                            "active": true,
                            "abcSortCode": "569150000",
                            "isOfi": false
                        },
                        {
                            "name": "AMML MICROFINANCE BANK",
                            "bankCode": "570",
                            "nipCode": "090116",
                            "active": true,
                            "abcSortCode": "570150000",
                            "isOfi": false
                        },
                        {
                            "name": "Boctrust Microfinance Bank",
                            "bankCode": "571",
                            "nipCode": "090117",
                            "active": true,
                            "abcSortCode": "571150000",
                            "isOfi": false
                        },
                        {
                            "name": "Innovectives Kesh",
                            "bankCode": "572",
                            "nipCode": "100029",
                            "active": true,
                            "abcSortCode": "572150000",
                            "isOfi": false
                        },
                        {
                            "name": "OHAFIA MFB",
                            "bankCode": "574",
                            "nipCode": "090119",
                            "active": true,
                            "abcSortCode": "574150000",
                            "isOfi": false
                        },
                        {
                            "name": "WETLAND MFB",
                            "bankCode": "512",
                            "nipCode": "090120",
                            "active": true,
                            "abcSortCode": "512150000",
                            "isOfi": false
                        },
                        {
                            "name": "GOWANS MFB",
                            "bankCode": "575",
                            "nipCode": "090122",
                            "active": true,
                            "abcSortCode": "575150000",
                            "isOfi": false
                        },
                        {
                            "name": "IBILE Microfinance Bank",
                            "bankCode": "576",
                            "nipCode": "090118",
                            "active": true,
                            "abcSortCode": "576150000",
                            "isOfi": false
                        },
                        {
                            "name": "TRUSTBANC J6 ",
                            "bankCode": "577",
                            "nipCode": "090123",
                            "active": true,
                            "abcSortCode": "577150000",
                            "isOfi": false
                        },
                        {
                            "name": "XSLNCE Microfinance Bank",
                            "bankCode": "579",
                            "nipCode": "090124",
                            "active": true,
                            "abcSortCode": "579150000",
                            "isOfi": false
                        },
                        {
                            "name": "Gateway Mortgage Bank",
                            "bankCode": "607",
                            "nipCode": "070009",
                            "active": true,
                            "abcSortCode": "607150000",
                            "isOfi": false
                        },
                        {
                            "name": "Abbey Mortgage Bank",
                            "bankCode": "404",
                            "nipCode": "070010",
                            "active": true,
                            "abcSortCode": "404150000",
                            "isOfi": false
                        },
                        {
                            "name": "REGENT MFB",
                            "bankCode": "580",
                            "nipCode": "090125",
                            "active": true,
                            "abcSortCode": "580150000",
                            "isOfi": false
                        },
                        {
                            "name": "FidFund MFB",
                            "bankCode": "581",
                            "nipCode": "090126",
                            "active": true,
                            "abcSortCode": "581150000",
                            "isOfi": false
                        },
                        {
                            "name": "NDIORAH MICROFINANCE BANK LTD",
                            "bankCode": "583",
                            "nipCode": "090128",
                            "active": true,
                            "abcSortCode": "583150000",
                            "isOfi": false
                        },
                        {
                            "name": "HASAL MFB",
                            "bankCode": "558",
                            "nipCode": "090121",
                            "active": true,
                            "abcSortCode": "558150000",
                            "isOfi": false
                        },
                        {
                            "name": "MONEYTRUST MFB",
                            "bankCode": "591",
                            "nipCode": "090129",
                            "active": true,
                            "abcSortCode": "591150000",
                            "isOfi": false
                        },
                        {
                            "name": "BC Kash MFB",
                            "bankCode": "582",
                            "nipCode": "090127",
                            "active": true,
                            "abcSortCode": "582150000",
                            "isOfi": false
                        },
                        {
                            "name": "CONSUMER  MFB",
                            "bankCode": "585",
                            "nipCode": "090130",
                            "active": true,
                            "abcSortCode": "585150000",
                            "isOfi": false
                        },
                        {
                            "name": "ALLWORKERS MFB",
                            "bankCode": "584",
                            "nipCode": "090131",
                            "active": true,
                            "abcSortCode": "584150000",
                            "isOfi": false
                        },
                        {
                            "name": "RICHWAY MFB",
                            "bankCode": "586",
                            "nipCode": "090132",
                            "active": true,
                            "abcSortCode": "586150000",
                            "isOfi": false
                        },
                        {
                            "name": "ACCION MFB",
                            "bankCode": "588",
                            "nipCode": "090134",
                            "active": true,
                            "abcSortCode": "588150000",
                            "isOfi": false
                        },
                        {
                            "name": "AL-BARKAH MFB",
                            "bankCode": "587",
                            "nipCode": "090133",
                            "active": true,
                            "abcSortCode": "587150000",
                            "isOfi": false
                        },
                        {
                            "name": "Microcred Microfinance Bank",
                            "bankCode": "564",
                            "nipCode": "090136",
                            "active": true,
                            "abcSortCode": "564150000",
                            "isOfi": false
                        },
                        {
                            "name": "LBIC Mortgage Bank",
                            "bankCode": "573",
                            "nipCode": "070012",
                            "active": true,
                            "abcSortCode": "573150000",
                            "isOfi": false
                        },
                        {
                            "name": "Personal Trust MFB",
                            "bankCode": "592",
                            "nipCode": "090135",
                            "active": true,
                            "abcSortCode": "592150000",
                            "isOfi": false
                        },
                        {
                            "name": "Refuge Mortgage Bank",
                            "bankCode": "589",
                            "nipCode": "070011",
                            "active": true,
                            "abcSortCode": "589150000",
                            "isOfi": false
                        },
                        {
                            "name": "Visa Microfinance Bank",
                            "bankCode": "593",
                            "nipCode": "090139",
                            "active": true,
                            "abcSortCode": "593150000",
                            "isOfi": false
                        },
                        {
                            "name": "APEKS Microfinance Bank",
                            "bankCode": "595",
                            "nipCode": "090143",
                            "active": true,
                            "abcSortCode": "595150000",
                            "isOfi": false
                        },
                        {
                            "name": "Yes MFB",
                            "bankCode": "594",
                            "nipCode": "090142",
                            "active": true,
                            "abcSortCode": "594150000",
                            "isOfi": false
                        },
                        {
                            "name": "PLATINUM MORTGAGE BANK",
                            "bankCode": "596",
                            "nipCode": "070013",
                            "active": true,
                            "abcSortCode": "596150000",
                            "isOfi": false
                        },
                        {
                            "name": "Royal Exchange MFB",
                            "bankCode": "597",
                            "nipCode": "090138",
                            "active": true,
                            "abcSortCode": "597150000",
                            "isOfi": false
                        },
                        {
                            "name": "Pecan Trust Microfinance Bank",
                            "bankCode": "598",
                            "nipCode": "090137",
                            "active": true,
                            "abcSortCode": "598150000",
                            "isOfi": false
                        },
                        {
                            "name": "CIT Microfinance Bank",
                            "bankCode": "599",
                            "nipCode": "090144",
                            "active": true,
                            "abcSortCode": "599150000",
                            "isOfi": false
                        },
                        {
                            "name": "Full range MFB",
                            "bankCode": "600",
                            "nipCode": "090145",
                            "active": true,
                            "abcSortCode": "600150000",
                            "isOfi": false
                        },
                        {
                            "name": "First Generation Mortgage Bank",
                            "bankCode": "604",
                            "nipCode": "070014",
                            "active": true,
                            "abcSortCode": "604150000",
                            "isOfi": false
                        },
                        {
                            "name": "Trident Microfinance Bank",
                            "bankCode": "611",
                            "nipCode": "090146",
                            "active": true,
                            "abcSortCode": "611150000",
                            "isOfi": false
                        },
                        {
                            "name": "Sagamu Microfinance Bank",
                            "bankCode": "610",
                            "nipCode": "090140",
                            "active": true,
                            "abcSortCode": "610150000",
                            "isOfi": false
                        },
                        {
                            "name": "Chikum Microfinance Bank",
                            "bankCode": "612",
                            "nipCode": "090141",
                            "active": true,
                            "abcSortCode": "612150000",
                            "isOfi": false
                        },
                        {
                            "name": "IRL Microfinance Bank",
                            "bankCode": "613",
                            "nipCode": "090149",
                            "active": true,
                            "abcSortCode": "613150000",
                            "isOfi": false
                        },
                        {
                            "name": "Infinity trust  Mortgage Bank",
                            "bankCode": "614",
                            "nipCode": "070016",
                            "active": true,
                            "abcSortCode": "614150000",
                            "isOfi": false
                        },
                        {
                            "name": "Nargata MFB",
                            "bankCode": "615",
                            "nipCode": "090152",
                            "active": true,
                            "abcSortCode": "615150000",
                            "isOfi": false
                        },
                        {
                            "name": "Mutual Trust Microfinance Bank",
                            "bankCode": "616",
                            "nipCode": "090151",
                            "active": true,
                            "abcSortCode": "616150000",
                            "isOfi": false
                        },
                        {
                            "name": "Bowen MFB",
                            "bankCode": "617",
                            "nipCode": "090148",
                            "active": true,
                            "abcSortCode": "617150000",
                            "isOfi": false
                        },
                        {
                            "name": "Hackman Microfinance Bank",
                            "bankCode": "618",
                            "nipCode": "090147",
                            "active": true,
                            "abcSortCode": "618150000",
                            "isOfi": false
                        },
                        {
                            "name": "Brent Mortgage Bank",
                            "bankCode": "416",
                            "nipCode": "070015",
                            "active": true,
                            "abcSortCode": "416150000",
                            "isOfi": false
                        },
                        {
                            "name": "FFS Microfinance Bank",
                            "bankCode": "620",
                            "nipCode": "090153",
                            "active": true,
                            "abcSortCode": "620150000",
                            "isOfi": false
                        },
                        {
                            "name": "Virtue MFB",
                            "bankCode": "621",
                            "nipCode": "090150",
                            "active": true,
                            "abcSortCode": "621150000",
                            "isOfi": false
                        },
                        {
                            "name": "Flutterwave Technology",
                            "bankCode": "622",
                            "nipCode": "110002",
                            "active": true,
                            "abcSortCode": "622150000",
                            "isOfi": false
                        },
                        {
                            "name": "ADVANS LA FAYETTE MFB",
                            "bankCode": "623",
                            "nipCode": "090155",
                            "active": true,
                            "abcSortCode": "623150000",
                            "isOfi": false
                        },
                        {
                            "name": "FCMB Easy Account",
                            "bankCode": "624",
                            "nipCode": "100031",
                            "active": true,
                            "abcSortCode": "624150000",
                            "isOfi": false
                        },
                        {
                            "name": "e-BARCs MFB",
                            "bankCode": "626",
                            "nipCode": "090156",
                            "active": true,
                            "abcSortCode": "626150000",
                            "isOfi": false
                        },
                        {
                            "name": "Addosser MFB",
                            "bankCode": "625",
                            "nipCode": "090160",
                            "active": true,
                            "abcSortCode": "625150000",
                            "isOfi": false
                        },
                        {
                            "name": "FUTO MFB",
                            "bankCode": "628",
                            "nipCode": "090158",
                            "active": true,
                            "abcSortCode": "628150000",
                            "isOfi": false
                        },
                        {
                            "name": "Infinity MFB",
                            "bankCode": "627",
                            "nipCode": "090157",
                            "active": true,
                            "abcSortCode": "627150000",
                            "isOfi": false
                        },
                        {
                            "name": "NOWNOW DIGITAL SYSTEMS LIMITED",
                            "bankCode": "629",
                            "nipCode": "100032",
                            "active": true,
                            "abcSortCode": "629150000",
                            "isOfi": false
                        },
                        {
                            "name": "Okpoga MFB",
                            "bankCode": "630",
                            "nipCode": "090161",
                            "active": true,
                            "abcSortCode": "630150000",
                            "isOfi": false
                        },
                        {
                            "name": "Credit Afrique MFB",
                            "bankCode": "631",
                            "nipCode": "090159",
                            "active": true,
                            "abcSortCode": "631150000",
                            "isOfi": false
                        },
                        {
                            "name": "First Multiple MFB",
                            "bankCode": "633",
                            "nipCode": "090163",
                            "active": true,
                            "abcSortCode": "633150000",
                            "isOfi": false
                        },
                        {
                            "name": "CEMCS MFB",
                            "bankCode": "632",
                            "nipCode": "090154",
                            "active": true,
                            "abcSortCode": "632150000",
                            "isOfi": false
                        },
                        {
                            "name": "First Royal Microfinance Bank",
                            "bankCode": "634",
                            "nipCode": "090164",
                            "active": true,
                            "abcSortCode": "634150000",
                            "isOfi": false
                        },
                        {
                            "name": "Petra Microfinance Bank",
                            "bankCode": "636",
                            "nipCode": "090165",
                            "active": true,
                            "abcSortCode": "636150000",
                            "isOfi": false
                        },
                        {
                            "name": "Daylight Microfinance Bank",
                            "bankCode": "638",
                            "nipCode": "090167",
                            "active": true,
                            "abcSortCode": "638150000",
                            "isOfi": false
                        },
                        {
                            "name": "Eso-E Microfinance Bank",
                            "bankCode": "640",
                            "nipCode": "090166",
                            "active": true,
                            "abcSortCode": "640150000",
                            "isOfi": false
                        },
                        {
                            "name": "Gashua Microfinance Bank",
                            "bankCode": "639",
                            "nipCode": "090168",
                            "active": true,
                            "abcSortCode": "639150000",
                            "isOfi": false
                        },
                        {
                            "name": "Haggai Mortgage Bank",
                            "bankCode": "641",
                            "nipCode": "070017",
                            "active": true,
                            "abcSortCode": "641150000",
                            "isOfi": false
                        },
                        {
                            "name": "Alphakapital MFB",
                            "bankCode": "642",
                            "nipCode": "090169",
                            "active": true,
                            "abcSortCode": "642150000",
                            "isOfi": false
                        },
                        {
                            "name": "Stanford MFB",
                            "bankCode": "643",
                            "nipCode": "090162",
                            "active": true,
                            "abcSortCode": "643150000",
                            "isOfi": false
                        },
                        {
                            "name": "Mainstreet MFB",
                            "bankCode": "645",
                            "nipCode": "090171",
                            "active": true,
                            "abcSortCode": "645150000",
                            "isOfi": false
                        },
                        {
                            "name": "Rahama MFB",
                            "bankCode": "644",
                            "nipCode": "090170",
                            "active": true,
                            "abcSortCode": "644150000",
                            "isOfi": false
                        },
                        {
                            "name": "Reliance MFB",
                            "bankCode": "646",
                            "nipCode": "090173",
                            "active": true,
                            "abcSortCode": "646150000",
                            "isOfi": false
                        },
                        {
                            "name": "Astrapolaris MFB",
                            "bankCode": "647",
                            "nipCode": "090172",
                            "active": true,
                            "abcSortCode": "647150000",
                            "isOfi": false
                        },
                        {
                            "name": "Malachy MFB",
                            "bankCode": "648",
                            "nipCode": "090174",
                            "active": true,
                            "abcSortCode": "648150000",
                            "isOfi": false
                        },
                        {
                            "name": "RUBIES MFB",
                            "bankCode": "649",
                            "nipCode": "090175",
                            "active": true,
                            "abcSortCode": "649150000",
                            "isOfi": false
                        },
                        {
                            "name": "Bosak MFB",
                            "bankCode": "650",
                            "nipCode": "090176",
                            "active": true,
                            "abcSortCode": "650150000",
                            "isOfi": false
                        },
                        {
                            "name": "GreenBank MFB",
                            "bankCode": "652",
                            "nipCode": "090178",
                            "active": true,
                            "abcSortCode": "652150000",
                            "isOfi": false
                        },
                        {
                            "name": "Amju MFB",
                            "bankCode": "503",
                            "nipCode": "090180",
                            "active": true,
                            "abcSortCode": "503150000",
                            "isOfi": false
                        },
                        {
                            "name": "FAST MFB",
                            "bankCode": "653",
                            "nipCode": "090179",
                            "active": true,
                            "abcSortCode": "653150000",
                            "isOfi": false
                        },
                        {
                            "name": "Esan MFB",
                            "bankCode": "510",
                            "nipCode": "090189",
                            "active": true,
                            "abcSortCode": "510150000",
                            "isOfi": false
                        },
                        {
                            "name": "Lapo MFB",
                            "bankCode": "651",
                            "nipCode": "090177",
                            "active": true,
                            "abcSortCode": "651150000",
                            "isOfi": false
                        },
                        {
                            "name": "KCMB MFB",
                            "bankCode": "655",
                            "nipCode": "090191",
                            "active": true,
                            "abcSortCode": "655150000",
                            "isOfi": false
                        },
                        {
                            "name": "Midland MFB",
                            "bankCode": "657",
                            "nipCode": "090192",
                            "active": true,
                            "abcSortCode": "657150000",
                            "isOfi": false
                        },
                        {
                            "name": "Baines Credit MFB",
                            "bankCode": "656",
                            "nipCode": "090188",
                            "active": true,
                            "abcSortCode": "656150000",
                            "isOfi": false
                        },
                        {
                            "name": "Unical MFB",
                            "bankCode": "658",
                            "nipCode": "090193",
                            "active": true,
                            "abcSortCode": "658150000",
                            "isOfi": false
                        },
                        {
                            "name": "NIRSAL National microfinance",
                            "bankCode": "659",
                            "nipCode": "090194",
                            "active": true,
                            "abcSortCode": "659150000",
                            "isOfi": false
                        },
                        {
                            "name": "Renmoney microfinance bank",
                            "bankCode": "660",
                            "nipCode": "090198",
                            "active": true,
                            "abcSortCode": "660150000",
                            "isOfi": false
                        },
                        {
                            "name": "ABU Microfinance bank",
                            "bankCode": "661",
                            "nipCode": "090197",
                            "active": true,
                            "abcSortCode": "661150000",
                            "isOfi": false
                        },
                        {
                            "name": "Mutual Benefits MFB",
                            "bankCode": "654",
                            "nipCode": "090190",
                            "active": true,
                            "abcSortCode": "654150000",
                            "isOfi": false
                        },
                        {
                            "name": "Pennywise Microfinance bank",
                            "bankCode": "662",
                            "nipCode": "090196",
                            "active": true,
                            "abcSortCode": "662150000",
                            "isOfi": false
                        },
                        {
                            "name": "UNN Microfinance bank",
                            "bankCode": "664",
                            "nipCode": "090251",
                            "active": true,
                            "abcSortCode": "664150000",
                            "isOfi": false
                        },
                        {
                            "name": "Grooming Microfinance bank",
                            "bankCode": "663",
                            "nipCode": "090195",
                            "active": true,
                            "abcSortCode": "663150000",
                            "isOfi": false
                        },
                        {
                            "name": "Alekun Microfinance bank",
                            "bankCode": "665",
                            "nipCode": "090259",
                            "active": true,
                            "abcSortCode": "665150000",
                            "isOfi": false
                        },
                        {
                            "name": "QuickFund Microfinance bank",
                            "bankCode": "666",
                            "nipCode": "090261",
                            "active": true,
                            "abcSortCode": "666150000",
                            "isOfi": false
                        },
                        {
                            "name": "Stellas Microfinance bank",
                            "bankCode": "667",
                            "nipCode": "090262",
                            "active": true,
                            "abcSortCode": "667150000",
                            "isOfi": false
                        },
                        {
                            "name": "Newdawn Microfinance Bank",
                            "bankCode": "669",
                            "nipCode": "090205",
                            "active": true,
                            "abcSortCode": "669150000",
                            "isOfi": false
                        },
                        {
                            "name": "Lovonus Microfinance bank",
                            "bankCode": "668",
                            "nipCode": "090265",
                            "active": true,
                            "abcSortCode": "668150000",
                            "isOfi": false
                        },
                        {
                            "name": "Above Only Microfinance bank",
                            "bankCode": "670",
                            "nipCode": "090260",
                            "active": true,
                            "abcSortCode": "670150000",
                            "isOfi": false
                        },
                        {
                            "name": "Auchi Microfinance bank",
                            "bankCode": "553",
                            "nipCode": "090264",
                            "active": true,
                            "abcSortCode": "553150000",
                            "isOfi": false
                        },
                        {
                            "name": "Kuda Microfinance Bank",
                            "bankCode": "672",
                            "nipCode": "090267",
                            "active": true,
                            "abcSortCode": "672150000",
                            "isOfi": false
                        },
                        {
                            "name": "Greenville Microfinance bank",
                            "bankCode": "673",
                            "nipCode": "090269",
                            "active": true,
                            "abcSortCode": "673150000",
                            "isOfi": false
                        },
                        {
                            "name": "Adeyemi College Staff ",
                            "bankCode": "674",
                            "nipCode": "090268",
                            "active": true,
                            "abcSortCode": "674150000",
                            "isOfi": false
                        },
                        {
                            "name": "Uniben Microfinance bank",
                            "bankCode": "671",
                            "nipCode": "090266",
                            "active": true,
                            "abcSortCode": "671150000",
                            "isOfi": false
                        },
                        {
                            "name": "AB Microfinance bank",
                            "bankCode": "675",
                            "nipCode": "090270",
                            "active": true,
                            "abcSortCode": "675150000",
                            "isOfi": false
                        },
                        {
                            "name": "Lavender Microfinance bank",
                            "bankCode": "676",
                            "nipCode": "090271",
                            "active": true,
                            "abcSortCode": "676150000",
                            "isOfi": false
                        },
                        {
                            "name": "Navy Microfinance bank",
                            "bankCode": "677",
                            "nipCode": "090263",
                            "active": true,
                            "abcSortCode": "677150000",
                            "isOfi": false
                        },
                        {
                            "name": "Imo Microfinance bank",
                            "bankCode": "678",
                            "nipCode": "090258",
                            "active": true,
                            "abcSortCode": "678150000",
                            "isOfi": false
                        },
                        {
                            "name": "Olabisi Onabanjo universityMFB",
                            "bankCode": "679",
                            "nipCode": "090272",
                            "active": true,
                            "abcSortCode": "679150000",
                            "isOfi": false
                        },
                        {
                            "name": "Emeralds MFB",
                            "bankCode": "680",
                            "nipCode": "090273",
                            "active": true,
                            "abcSortCode": "680150000",
                            "isOfi": false
                        },
                        {
                            "name": "Trustfund Microfinance Bank",
                            "bankCode": "682",
                            "nipCode": "090276",
                            "active": true,
                            "abcSortCode": "682150000",
                            "isOfi": false
                        },
                        {
                            "name": "Interswitch Limited",
                            "bankCode": "683",
                            "nipCode": "110003",
                            "active": true,
                            "abcSortCode": "683150000",
                            "isOfi": false
                        },
                        {
                            "name": "Alhayat MFB ",
                            "bankCode": "684",
                            "nipCode": "090277",
                            "active": true,
                            "abcSortCode": "684150000",
                            "isOfi": false
                        },
                        {
                            "name": "First Apple Limited",
                            "bankCode": "687",
                            "nipCode": "110004",
                            "active": true,
                            "abcSortCode": "687150000",
                            "isOfi": false
                        },
                        {
                            "name": "Ikire MFB",
                            "bankCode": "688",
                            "nipCode": "090279",
                            "active": true,
                            "abcSortCode": "688150000",
                            "isOfi": false
                        },
                        {
                            "name": "MINT-FINEX MFB",
                            "bankCode": "693",
                            "nipCode": "090281",
                            "active": true,
                            "abcSortCode": "693150000",
                            "isOfi": false
                        },
                        {
                            "name": "Arise MFB",
                            "bankCode": "692",
                            "nipCode": "090282",
                            "active": true,
                            "abcSortCode": "692150000",
                            "isOfi": false
                        },
                        {
                            "name": "3line Card management Limited",
                            "bankCode": "691",
                            "nipCode": "110005",
                            "active": true,
                            "abcSortCode": "691150000",
                            "isOfi": false
                        },
                        {
                            "name": "Itex Integrated Services ",
                            "bankCode": "694",
                            "nipCode": "090211",
                            "active": true,
                            "abcSortCode": "694150000",
                            "isOfi": false
                        },
                        {
                            "name": "Paystack Payments Limited",
                            "bankCode": "695",
                            "nipCode": "110006",
                            "active": true,
                            "abcSortCode": "695150000",
                            "isOfi": false
                        },
                        {
                            "name": "Pillar MFB",
                            "bankCode": "700",
                            "nipCode": "090289",
                            "active": true,
                            "abcSortCode": "700150000",
                            "isOfi": false
                        },
                        {
                            "name": "HALALCREDIT MICROFINANCE BANK",
                            "bankCode": "702",
                            "nipCode": "090291",
                            "active": true,
                            "abcSortCode": "702150000",
                            "isOfi": false
                        },
                        {
                            "name": "FCT MFB",
                            "bankCode": "701",
                            "nipCode": "090290",
                            "active": true,
                            "abcSortCode": "701150000",
                            "isOfi": false
                        },
                        {
                            "name": "First Option MFB",
                            "bankCode": "696",
                            "nipCode": "090285",
                            "active": true,
                            "abcSortCode": "696150000",
                            "isOfi": false
                        },
                        {
                            "name": "Megapraise Microfinance Bank",
                            "bankCode": "689",
                            "nipCode": "090280",
                            "active": true,
                            "abcSortCode": "689150000",
                            "isOfi": false
                        },
                        {
                            "name": "Afekhafe MFB",
                            "bankCode": "704",
                            "nipCode": "090292",
                            "active": true,
                            "abcSortCode": "704150000",
                            "isOfi": false
                        },
                        {
                            "name": "THRIVE MFB",
                            "bankCode": "703",
                            "nipCode": "090283",
                            "active": true,
                            "abcSortCode": "703150000",
                            "isOfi": false
                        },
                        {
                            "name": "Prestige Microfinance bank",
                            "bankCode": "681",
                            "nipCode": "090274",
                            "active": true,
                            "abcSortCode": "681150000",
                            "isOfi": false
                        },
                        {
                            "name": "Meridian MFB",
                            "bankCode": "685",
                            "nipCode": "090275",
                            "active": true,
                            "abcSortCode": "685150000",
                            "isOfi": false
                        },
                        {
                            "name": "Glory MFB",
                            "bankCode": "686",
                            "nipCode": "090278",
                            "active": true,
                            "abcSortCode": "686150000",
                            "isOfi": false
                        },
                        {
                            "name": "Eagle Flight MFB",
                            "bankCode": "734",
                            "nipCode": "090294",
                            "active": true,
                            "abcSortCode": "734150000",
                            "isOfi": false
                        },
                        {
                            "name": "Alert MFB",
                            "bankCode": "706",
                            "nipCode": "090297",
                            "active": true,
                            "abcSortCode": "706150000",
                            "isOfi": false
                        },
                        {
                            "name": "Polyuwanna MFB",
                            "bankCode": "707",
                            "nipCode": "090296",
                            "active": true,
                            "abcSortCode": "707150000",
                            "isOfi": false
                        },
                        {
                            "name": "Team APT",
                            "bankCode": "708",
                            "nipCode": "110007",
                            "active": true,
                            "abcSortCode": "708150000",
                            "isOfi": false
                        },
                        {
                            "name": "Kontagora MFB",
                            "bankCode": "709",
                            "nipCode": "090299",
                            "active": true,
                            "abcSortCode": "709150000",
                            "isOfi": false
                        },
                        {
                            "name": "BETA-ACCESS YELLO",
                            "bankCode": "710",
                            "nipCode": "100052",
                            "active": true,
                            "abcSortCode": "710150000",
                            "isOfi": false
                        },
                        {
                            "name": "Omiye MFB",
                            "bankCode": "705",
                            "nipCode": "090295",
                            "active": true,
                            "abcSortCode": "705150000",
                            "isOfi": false
                        },
                        {
                            "name": "ASSET MATRIX",
                            "bankCode": "711",
                            "nipCode": "090287",
                            "active": true,
                            "abcSortCode": "711150000",
                            "isOfi": false
                        },
                        {
                            "name": "Evangel MFB",
                            "bankCode": "713",
                            "nipCode": "090304",
                            "active": true,
                            "abcSortCode": "713150000",
                            "isOfi": false
                        },
                        {
                            "name": "BRETHREN MICROFINANCE BANK",
                            "bankCode": "712",
                            "nipCode": "090293",
                            "active": true,
                            "abcSortCode": "712150000",
                            "isOfi": false
                        },
                        {
                            "name": "FederalPoly NasarawaMFB",
                            "bankCode": "714",
                            "nipCode": "090298",
                            "active": true,
                            "abcSortCode": "714150000",
                            "isOfi": false
                        },
                        {
                            "name": "BRIGHTWAY MFB",
                            "bankCode": "715",
                            "nipCode": "090308",
                            "active": true,
                            "abcSortCode": "715150000",
                            "isOfi": false
                        },
                        {
                            "name": "Purplemoney MFB",
                            "bankCode": "717",
                            "nipCode": "090303",
                            "active": true,
                            "abcSortCode": "717150000",
                            "isOfi": false
                        },
                        {
                            "name": "Sulsap MFB",
                            "bankCode": "716",
                            "nipCode": "090305",
                            "active": true,
                            "abcSortCode": "716150000",
                            "isOfi": false
                        },
                        {
                            "name": "U &C MFB",
                            "bankCode": "718",
                            "nipCode": "090315",
                            "active": true,
                            "abcSortCode": "718150000",
                            "isOfi": false
                        },
                        {
                            "name": "PalmPay",
                            "bankCode": "719",
                            "nipCode": "100033",
                            "active": true,
                            "abcSortCode": "719150000",
                            "isOfi": false
                        },
                        {
                            "name": "Edfin MFB",
                            "bankCode": "720",
                            "nipCode": "090310",
                            "active": true,
                            "abcSortCode": "720150000",
                            "isOfi": false
                        },
                        {
                            "name": "Patrick Gold",
                            "bankCode": "721",
                            "nipCode": "090317",
                            "active": true,
                            "abcSortCode": "721150000",
                            "isOfi": false
                        },
                        {
                            "name": "Mayfair  MFB",
                            "bankCode": "722",
                            "nipCode": "090321",
                            "active": true,
                            "abcSortCode": "722150000",
                            "isOfi": false
                        },
                        {
                            "name": "SPARKLE MICROFINANCE BANK",
                            "bankCode": "723",
                            "nipCode": "090325",
                            "active": true,
                            "abcSortCode": "723150000",
                            "isOfi": false
                        },
                        {
                            "name": "Mainland MICROFINANCE BANK ",
                            "bankCode": "724",
                            "nipCode": "090323",
                            "active": true,
                            "abcSortCode": "724150000",
                            "isOfi": false
                        },
                        {
                            "name": "Balogun Gambari MFB",
                            "bankCode": "725",
                            "nipCode": "090326",
                            "active": true,
                            "abcSortCode": "725150000",
                            "isOfi": false
                        },
                        {
                            "name": "Kadpoly MICROFINANCE BANK",
                            "bankCode": "727",
                            "nipCode": "090320",
                            "active": true,
                            "abcSortCode": "727150000",
                            "isOfi": false
                        },
                        {
                            "name": "MayFresh Mortgage Bank",
                            "bankCode": "728",
                            "nipCode": "070019",
                            "active": true,
                            "abcSortCode": "728150000",
                            "isOfi": false
                        },
                        {
                            "name": "Bayero MICROFINANCE BANK",
                            "bankCode": "729",
                            "nipCode": "090316",
                            "active": true,
                            "abcSortCode": "729150000",
                            "isOfi": false
                        },
                        {
                            "name": "Trust MFB",
                            "bankCode": "732",
                            "nipCode": "090327",
                            "active": true,
                            "abcSortCode": "732150000",
                            "isOfi": false
                        },
                        {
                            "name": "Kadick Integration Limited",
                            "bankCode": "731",
                            "nipCode": "110008",
                            "active": true,
                            "abcSortCode": "731150000",
                            "isOfi": false
                        },
                        {
                            "name": "Evergreen MICROFINANCE BANK",
                            "bankCode": "735",
                            "nipCode": "090332",
                            "active": true,
                            "abcSortCode": "735150000",
                            "isOfi": false
                        },
                        {
                            "name": "FEDERAL UNIVERSITY DUTSE MFB",
                            "bankCode": "730",
                            "nipCode": "090318",
                            "active": true,
                            "abcSortCode": "730150000",
                            "isOfi": false
                        },
                        {
                            "name": "Xpress Payments",
                            "bankCode": "738",
                            "nipCode": "090201",
                            "active": true,
                            "abcSortCode": "738150000",
                            "isOfi": false
                        },
                        {
                            "name": "REPHIDIM MICROFINANCE BANK",
                            "bankCode": "737",
                            "nipCode": "090322",
                            "active": true,
                            "abcSortCode": "737150000",
                            "isOfi": false
                        },
                        {
                            "name": "IKENNE MFB",
                            "bankCode": "733",
                            "nipCode": "090324",
                            "active": true,
                            "abcSortCode": "733150000",
                            "isOfi": false
                        },
                        {
                            "name": "BIPC  MICROFINANCE BANK ",
                            "bankCode": "736",
                            "nipCode": "090336",
                            "active": true,
                            "abcSortCode": "736150000",
                            "isOfi": false
                        },
                        {
                            "name": "UNAAB MICROFINANCE BANK",
                            "bankCode": "740",
                            "nipCode": "090331",
                            "active": true,
                            "abcSortCode": "740150000",
                            "isOfi": false
                        },
                        {
                            "name": "CASHCONNECT MICROFINANCE BANK",
                            "bankCode": "748",
                            "nipCode": "090360",
                            "active": true,
                            "abcSortCode": "748150000",
                            "isOfi": false
                        },
                        {
                            "name": "EYOWO MICROFINANCE BANK",
                            "bankCode": "747",
                            "nipCode": "090328",
                            "active": true,
                            "abcSortCode": "747150000",
                            "isOfi": false
                        },
                        {
                            "name": "Oche MFB",
                            "bankCode": "739",
                            "nipCode": "090333",
                            "active": true,
                            "abcSortCode": "739150000",
                            "isOfi": false
                        },
                        {
                            "name": "HEADWAY MICROFINANCE BANK",
                            "bankCode": "749",
                            "nipCode": "090363",
                            "active": true,
                            "abcSortCode": "749150000",
                            "isOfi": false
                        },
                        {
                            "name": "ACCELEREX NETWORK",
                            "bankCode": "750",
                            "nipCode": "090202",
                            "active": true,
                            "abcSortCode": "750150000",
                            "isOfi": false
                        },
                        {
                            "name": "Nuture MFB",
                            "bankCode": "752",
                            "nipCode": "090364",
                            "active": true,
                            "abcSortCode": "752150000",
                            "isOfi": false
                        },
                        {
                            "name": "Agosasa MICROFINANCE BANK",
                            "bankCode": "753",
                            "nipCode": "090371",
                            "active": true,
                            "abcSortCode": "753150000",
                            "isOfi": false
                        },
                        {
                            "name": "COASTLINE MICROFINANCE BANK",
                            "bankCode": "756",
                            "nipCode": "090374",
                            "active": true,
                            "abcSortCode": "756150000",
                            "isOfi": false
                        },
                        {
                            "name": "LEGEND MICROFINANCE BANK",
                            "bankCode": "757",
                            "nipCode": "090372",
                            "active": true,
                            "abcSortCode": "757150000",
                            "isOfi": false
                        },
                        {
                            "name": "TF MICROFINANCE BANK",
                            "bankCode": "758",
                            "nipCode": "090373",
                            "active": true,
                            "abcSortCode": "758150000",
                            "isOfi": false
                        },
                        {
                            "name": "Neptune MICROFINANCE BANK",
                            "bankCode": "760",
                            "nipCode": "090329",
                            "active": true,
                            "abcSortCode": "760150000",
                            "isOfi": false
                        },
                        {
                            "name": "Molusi MICROFINANCE BANK",
                            "bankCode": "759",
                            "nipCode": "090362",
                            "active": true,
                            "abcSortCode": "759150000",
                            "isOfi": false
                        },
                        {
                            "name": "Seedvest MICROFINANCE BANK",
                            "bankCode": "761",
                            "nipCode": "090369",
                            "active": true,
                            "abcSortCode": "761150000",
                            "isOfi": false
                        },
                        {
                            "name": "APPLE MICROFINANCE BANK",
                            "bankCode": "762",
                            "nipCode": "090376",
                            "active": true,
                            "abcSortCode": "762150000",
                            "isOfi": false
                        },
                        {
                            "name": "New Golden Pastures MFB",
                            "bankCode": "764",
                            "nipCode": "090378",
                            "active": true,
                            "abcSortCode": "764150000",
                            "isOfi": false
                        },
                        {
                            "name": "Isaleoyo MICROFINANCE BANK",
                            "bankCode": "765",
                            "nipCode": "090377",
                            "active": true,
                            "abcSortCode": "765150000",
                            "isOfi": false
                        },
                        {
                            "name": "Manny Microfinance Bank",
                            "bankCode": "770",
                            "nipCode": "090383",
                            "active": true,
                            "abcSortCode": "770150000",
                            "isOfi": false
                        },
                        {
                            "name": "Arca Payments Company Limited ",
                            "bankCode": "769",
                            "nipCode": "110011",
                            "active": true,
                            "abcSortCode": "769150000",
                            "isOfi": false
                        },
                        {
                            "name": "Corestep MICROFINANCE BANK",
                            "bankCode": "766",
                            "nipCode": "090365",
                            "active": true,
                            "abcSortCode": "766150000",
                            "isOfi": false
                        },
                        {
                            "name": "Firmus MICROFINANCE BANK",
                            "bankCode": "751",
                            "nipCode": "090366",
                            "active": true,
                            "abcSortCode": "751150000",
                            "isOfi": false
                        },
                        {
                            "name": "Venture Garden Nigeria Limited",
                            "bankCode": "767",
                            "nipCode": "110009",
                            "active": true,
                            "abcSortCode": "767150000",
                            "isOfi": false
                        },
                        {
                            "name": "INTERLAND MICROFINANCE BANK",
                            "bankCode": "773",
                            "nipCode": "090386",
                            "active": true,
                            "abcSortCode": "773150000",
                            "isOfi": false
                        },
                        {
                            "name": "Davodani Microfinance Bank",
                            "bankCode": "774",
                            "nipCode": "090391",
                            "active": true,
                            "abcSortCode": "774150000",
                            "isOfi": false
                        },
                        {
                            "name": "GTI  Microfinance Bank",
                            "bankCode": "775",
                            "nipCode": "090385",
                            "active": true,
                            "abcSortCode": "775150000",
                            "isOfi": false
                        },
                        {
                            "name": "Ilisan Microfinance Bank",
                            "bankCode": "754",
                            "nipCode": "090370",
                            "active": true,
                            "abcSortCode": "754150000",
                            "isOfi": false
                        },
                        {
                            "name": "QR Payments",
                            "bankCode": "777",
                            "nipCode": "110013",
                            "active": true,
                            "abcSortCode": "777150000",
                            "isOfi": false
                        },
                        {
                            "name": "EK-Reliable Microfinance Bank",
                            "bankCode": "772",
                            "nipCode": "090389",
                            "active": true,
                            "abcSortCode": "772150000",
                            "isOfi": false
                        },
                        {
                            "name": "Mozfin Microfinance Bank",
                            "bankCode": "780",
                            "nipCode": "090392",
                            "active": true,
                            "abcSortCode": "780150000",
                            "isOfi": false
                        },
                        {
                            "name": "9 Payment Service Bank",
                            "bankCode": "802",
                            "nipCode": "120001",
                            "active": true,
                            "abcSortCode": "802150000",
                            "isOfi": false
                        },
                        {
                            "name": "Yobe MFB",
                            "bankCode": "778",
                            "nipCode": "090252",
                            "active": true,
                            "abcSortCode": "778150000",
                            "isOfi": false
                        },
                        {
                            "name": "KREDI MONEY MICROFINANCE BANK ",
                            "bankCode": "781",
                            "nipCode": "090380",
                            "active": true,
                            "abcSortCode": "781150000",
                            "isOfi": false
                        },
                        {
                            "name": "Borgu MFB",
                            "bankCode": "783",
                            "nipCode": "090395",
                            "active": true,
                            "abcSortCode": "783150000",
                            "isOfi": false
                        },
                        {
                            "name": "Oscotech MFB",
                            "bankCode": "782",
                            "nipCode": "090396",
                            "active": true,
                            "abcSortCode": "782150000",
                            "isOfi": false
                        },
                        {
                            "name": "Federal Polytechnic Nekede MFB",
                            "bankCode": "788",
                            "nipCode": "090398",
                            "active": true,
                            "abcSortCode": "788150000",
                            "isOfi": false
                        },
                        {
                            "name": "Parallex Bank",
                            "bankCode": "104",
                            "nipCode": "000030",
                            "active": true,
                            "abcSortCode": "104150000",
                            "isOfi": false
                        },
                        {
                            "name": "Premium Trust bank",
                            "bankCode": "105",
                            "nipCode": "000031",
                            "active": true,
                            "abcSortCode": "105150000",
                            "isOfi": false
                        },
                        {
                            "name": "Bridgeway Microfinance Bank",
                            "bankCode": "789",
                            "nipCode": "090393",
                            "active": true,
                            "abcSortCode": "789150000",
                            "isOfi": false
                        },
                        {
                            "name": "Girei Microfinance Bank",
                            "bankCode": "784",
                            "nipCode": "090186",
                            "active": true,
                            "abcSortCode": "784150000",
                            "isOfi": false
                        },
                        {
                            "name": "Nwannegadi Microfinance Bank",
                            "bankCode": "787",
                            "nipCode": "090399",
                            "active": true,
                            "abcSortCode": "787150000",
                            "isOfi": false
                        },
                        {
                            "name": "Finca Microfinance Bank",
                            "bankCode": "785",
                            "nipCode": "090400",
                            "active": true,
                            "abcSortCode": "785150000",
                            "isOfi": false
                        },
                        {
                            "name": "AMAC MICROFINANCE BANK",
                            "bankCode": "790",
                            "nipCode": "090394",
                            "active": true,
                            "abcSortCode": "790150000",
                            "isOfi": false
                        },
                        {
                            "name": "Cyberspace Limited",
                            "bankCode": "791",
                            "nipCode": "110014",
                            "active": true,
                            "abcSortCode": "791150000",
                            "isOfi": false
                        },
                        {
                            "name": "Zwallet",
                            "bankCode": "792",
                            "nipCode": "100034",
                            "active": true,
                            "abcSortCode": "792150000",
                            "isOfi": false
                        },
                        {
                            "name": "Shepherd Trust MFB",
                            "bankCode": "793",
                            "nipCode": "090401",
                            "active": true,
                            "abcSortCode": "793150000",
                            "isOfi": false
                        },
                        {
                            "name": "HopePSB",
                            "bankCode": "800",
                            "nipCode": "120002",
                            "active": true,
                            "abcSortCode": "800150000",
                            "isOfi": false
                        },
                        {
                            "name": "Olowolagba Microfinance Bank",
                            "bankCode": "794",
                            "nipCode": "090404",
                            "active": true,
                            "abcSortCode": "794150000",
                            "isOfi": false
                        },
                        {
                            "name": "MONIEPOINT MICROFINANCE BANK",
                            "bankCode": "796",
                            "nipCode": "090405",
                            "active": true,
                            "abcSortCode": "796150000",
                            "isOfi": false
                        },
                        {
                            "name": "Business Support Mfb",
                            "bankCode": "797",
                            "nipCode": "090406",
                            "active": true,
                            "abcSortCode": "797150000",
                            "isOfi": false
                        },
                        {
                            "name": "Maritime Microfinance Bank",
                            "bankCode": "798",
                            "nipCode": "090410",
                            "active": true,
                            "abcSortCode": "798150000",
                            "isOfi": false
                        },
                        {
                            "name": "GMB Microfinance Bank",
                            "bankCode": "799",
                            "nipCode": "090408",
                            "active": true,
                            "abcSortCode": "799150000",
                            "isOfi": false
                        },
                        {
                            "name": "FCMB MFB",
                            "bankCode": "135",
                            "nipCode": "090409",
                            "active": true,
                            "abcSortCode": "135150000",
                            "isOfi": false
                        },
                        {
                            "name": "M36",
                            "bankCode": "801",
                            "nipCode": "100035",
                            "active": true,
                            "abcSortCode": "801150000",
                            "isOfi": false
                        },
                        {
                            "name": "GiGinya Microfinance Bank",
                            "bankCode": "134",
                            "nipCode": "090411",
                            "active": true,
                            "abcSortCode": "134150000",
                            "isOfi": false
                        },
                        {
                            "name": "Imowo Microfinance Bank",
                            "bankCode": "804",
                            "nipCode": "090417",
                            "active": true,
                            "abcSortCode": "804150000",
                            "isOfi": false
                        },
                        {
                            "name": "Calabar Microfinance Bank",
                            "bankCode": "803",
                            "nipCode": "090415",
                            "active": true,
                            "abcSortCode": "803150000",
                            "isOfi": false
                        },
                        {
                            "name": "Chibueze Microfinance Bank",
                            "bankCode": "806",
                            "nipCode": "090416",
                            "active": true,
                            "abcSortCode": "806150000",
                            "isOfi": false
                        },
                        {
                            "name": "COOP Mortgage  Bank",
                            "bankCode": "807",
                            "nipCode": "070021",
                            "active": true,
                            "abcSortCode": "807150000",
                            "isOfi": false
                        },
                        {
                            "name": "Preeminent Microfinance Bank",
                            "bankCode": "808",
                            "nipCode": "090412",
                            "active": true,
                            "abcSortCode": "808150000",
                            "isOfi": false
                        },
                        {
                            "name": "CHANELLE MICROFINANCE  BANK",
                            "bankCode": "810",
                            "nipCode": "090397",
                            "active": true,
                            "abcSortCode": "810150000",
                            "isOfi": false
                        },
                        {
                            "name": "Peace Microfinance Bank",
                            "bankCode": "812",
                            "nipCode": "090402",
                            "active": true,
                            "abcSortCode": "812150000",
                            "isOfi": false
                        },
                        {
                            "name": "Landgold  Microfinance Bank",
                            "bankCode": "816",
                            "nipCode": "090422",
                            "active": true,
                            "abcSortCode": "816150000",
                            "isOfi": false
                        },
                        {
                            "name": "Sunbeam Microfinance Bank",
                            "bankCode": "817",
                            "nipCode": "090302",
                            "active": true,
                            "abcSortCode": "817150000",
                            "isOfi": false
                        },
                        {
                            "name": "LETSHEGO MICROFINANCE BANK",
                            "bankCode": "815",
                            "nipCode": "090420",
                            "active": true,
                            "abcSortCode": "815150000",
                            "isOfi": false
                        },
                        {
                            "name": "Benysta  Microfinance Bank",
                            "bankCode": "813",
                            "nipCode": "090413",
                            "active": true,
                            "abcSortCode": "813150000",
                            "isOfi": false
                        },
                        {
                            "name": "Banex Microfinance Bank",
                            "bankCode": "819",
                            "nipCode": "090425",
                            "active": true,
                            "abcSortCode": "819150000",
                            "isOfi": false
                        },
                        {
                            "name": "Abucoop  Microfinance Bank",
                            "bankCode": "820",
                            "nipCode": "090424",
                            "active": true,
                            "abcSortCode": "820150000",
                            "isOfi": false
                        },
                        {
                            "name": "Izon  Microfinance Bank",
                            "bankCode": "814",
                            "nipCode": "090421",
                            "active": true,
                            "abcSortCode": "814150000",
                            "isOfi": false
                        },
                        {
                            "name": "Tangerine Money",
                            "bankCode": "821",
                            "nipCode": "090426",
                            "active": true,
                            "abcSortCode": "821150000",
                            "isOfi": false
                        },
                        {
                            "name": "Bluewhales  Microfinance Bank",
                            "bankCode": "824",
                            "nipCode": "090431",
                            "active": true,
                            "abcSortCode": "824150000",
                            "isOfi": false
                        },
                        {
                            "name": "Highland Microfinance Bank",
                            "bankCode": "818",
                            "nipCode": "090418",
                            "active": true,
                            "abcSortCode": "818150000",
                            "isOfi": false
                        },
                        {
                            "name": "Ilora Microfinance Bank",
                            "bankCode": "823",
                            "nipCode": "090430",
                            "active": true,
                            "abcSortCode": "823150000",
                            "isOfi": false
                        },
                        {
                            "name": "WinView Microfinance Bank",
                            "bankCode": "811",
                            "nipCode": "090419",
                            "active": true,
                            "abcSortCode": "811150000",
                            "isOfi": false
                        },
                        {
                            "name": "Ishie  Microfinance Bank",
                            "bankCode": "822",
                            "nipCode": "090428",
                            "active": true,
                            "abcSortCode": "822150000",
                            "isOfi": false
                        },
                        {
                            "name": "Mautech  Microfinance Bank",
                            "bankCode": "825",
                            "nipCode": "090423",
                            "active": true,
                            "abcSortCode": "825150000",
                            "isOfi": false
                        },
                        {
                            "name": "EBSU Microfinance Bank",
                            "bankCode": "830",
                            "nipCode": "090427",
                            "active": true,
                            "abcSortCode": "830150000",
                            "isOfi": false
                        },
                        {
                            "name": "OPTIMUS BANK LIMITED ",
                            "bankCode": "107",
                            "nipCode": "000036",
                            "active": true,
                            "abcSortCode": "107150000",
                            "isOfi": false
                        },
                        {
                            "name": "Greenwich Merchant Bank",
                            "bankCode": "562",
                            "nipCode": "060004",
                            "active": true,
                            "abcSortCode": "562150000",
                            "isOfi": false
                        },
                        {
                            "name": "EKIMOGUN MICROFINANCE BANK",
                            "bankCode": "441",
                            "nipCode": "090552",
                            "active": true,
                            "abcSortCode": "441150000",
                            "isOfi": false
                        },
                        {
                            "name": "Ikoyi-Osun Microfinance Bank",
                            "bankCode": "978",
                            "nipCode": "090536",
                            "active": true,
                            "abcSortCode": "978150000",
                            "isOfi": false
                        },
                        {
                            "name": "Money Master PSB ",
                            "bankCode": "946",
                            "nipCode": "120005",
                            "active": true,
                            "abcSortCode": "946150000",
                            "isOfi": false
                        },
                        {
                            "name": "Gwong Microfinance bank",
                            "bankCode": "919",
                            "nipCode": "090500",
                            "active": true,
                            "abcSortCode": "919150000",
                            "isOfi": false
                        },
                        {
                            "name": "OTECH MICROFINANCE BANK LTD",
                            "bankCode": "480",
                            "nipCode": "090580",
                            "active": true,
                            "abcSortCode": "480150000",
                            "isOfi": false
                        },
                        {
                            "name": "THE ALTERNATIVE BANK LIMITED",
                            "bankCode": "116",
                            "nipCode": "000037",
                            "active": true,
                            "abcSortCode": "116150000",
                            "isOfi": false
                        },
                        {
                            "name": "AKUCHUKWU MFB LTD",
                            "bankCode": "462",
                            "nipCode": "090561",
                            "active": true,
                            "abcSortCode": "462150000",
                            "isOfi": false
                        },
                        {
                            "name": "SIGNATURE BANK",
                            "bankCode": "106",
                            "nipCode": "000034",
                            "active": true,
                            "abcSortCode": "106150000",
                            "isOfi": false
                        },
                        {
                            "name": "Fortis Microfinance Bank",
                            "bankCode": "055",
                            "nipCode": "070002",
                            "active": true,
                            "abcSortCode": "055150000",
                            "isOfi": false
                        },
                        {
                            "name": "SmartCash Payment Service Bank",
                            "bankCode": "942",
                            "nipCode": "120004",
                            "active": true,
                            "abcSortCode": "942150000",
                            "isOfi": false
                        },
                        {
                            "name": "BOJI BOJI MFB",
                            "bankCode": "912",
                            "nipCode": "090494",
                            "active": true,
                            "abcSortCode": "912150000",
                            "isOfi": false
                        },
                        {
                            "name": "ANIOCHA MFB",
                            "bankCode": "900",
                            "nipCode": "090469",
                            "active": true,
                            "abcSortCode": "900150000",
                            "isOfi": false
                        },
                        {
                            "name": "ABSU MFB",
                            "bankCode": "386",
                            "nipCode": "090640",
                            "active": true,
                            "abcSortCode": "386150000",
                            "isOfi": false
                        },
                        {
                            "name": "Kegow",
                            "bankCode": "864",
                            "nipCode": "100015",
                            "active": true,
                            "abcSortCode": "864150000",
                            "isOfi": false
                        },
                        {
                            "name": "PARRALEX",
                            "bankCode": "526",
                            "nipCode": "090004",
                            "active": true,
                            "abcSortCode": "526150000",
                            "isOfi": false
                        },
                        {
                            "name": "SAFEHAVEN MFB",
                            "bankCode": "697",
                            "nipCode": "090286",
                            "active": true,
                            "abcSortCode": "697150000",
                            "isOfi": false
                        },
                        {
                            "name": "FUTMINNA MFB",
                            "bankCode": "832",
                            "nipCode": "090438",
                            "active": true,
                            "abcSortCode": "832150000",
                            "isOfi": false
                        },
                        {
                            "name": "OAKLAND MFB",
                            "bankCode": "833",
                            "nipCode": "090437",
                            "active": true,
                            "abcSortCode": "833150000",
                            "isOfi": false
                        },
                        {
                            "name": "LINKS  MFB",
                            "bankCode": "831",
                            "nipCode": "090435",
                            "active": true,
                            "abcSortCode": "831150000",
                            "isOfi": false
                        },
                        {
                            "name": "MEMPHIS MFB",
                            "bankCode": "826",
                            "nipCode": "090432",
                            "active": true,
                            "abcSortCode": "826150000",
                            "isOfi": false
                        },
                        {
                            "name": "STB MORTGAGE BANK",
                            "bankCode": "882",
                            "nipCode": "070022",
                            "active": true,
                            "abcSortCode": "882150000",
                            "isOfi": false
                        },
                        {
                            "name": "SPECTRUM MFB",
                            "bankCode": "853",
                            "nipCode": "090436",
                            "active": true,
                            "abcSortCode": "853150000",
                            "isOfi": false
                        },
                        {
                            "name": "ILLORIN MFB",
                            "bankCode": "829",
                            "nipCode": "090350",
                            "active": true,
                            "abcSortCode": "829150000",
                            "isOfi": false
                        },
                        {
                            "name": "IBETO  MFB",
                            "bankCode": "851",
                            "nipCode": "090439",
                            "active": true,
                            "abcSortCode": "851150000",
                            "isOfi": false
                        },
                        {
                            "name": "INSIGHT MFB",
                            "bankCode": "855",
                            "nipCode": "090434",
                            "active": true,
                            "abcSortCode": "855150000",
                            "isOfi": false
                        },
                        {
                            "name": "GIWA MFB",
                            "bankCode": "837",
                            "nipCode": "090441",
                            "active": true,
                            "abcSortCode": "837150000",
                            "isOfi": false
                        },
                        {
                            "name": "BONGHE MFB",
                            "bankCode": "838",
                            "nipCode": "090319",
                            "active": true,
                            "abcSortCode": "838150000",
                            "isOfi": false
                        },
                        {
                            "name": "FAME MFB",
                            "bankCode": "836",
                            "nipCode": "090330",
                            "active": true,
                            "abcSortCode": "836150000",
                            "isOfi": false
                        },
                        {
                            "name": "RIMA MFB",
                            "bankCode": "839",
                            "nipCode": "090443",
                            "active": true,
                            "abcSortCode": "839150000",
                            "isOfi": false
                        },
                        {
                            "name": "CRUTECH  MFB",
                            "bankCode": "805",
                            "nipCode": "090414",
                            "active": true,
                            "abcSortCode": "805150000",
                            "isOfi": false
                        },
                        {
                            "name": "UNILORIN MFB",
                            "bankCode": "834",
                            "nipCode": "090341",
                            "active": true,
                            "abcSortCode": "834150000",
                            "isOfi": false
                        },
                        {
                            "name": "ISUA MFB",
                            "bankCode": "175",
                            "nipCode": "090701",
                            "active": true,
                            "abcSortCode": "175150000",
                            "isOfi": false
                        },
                        {
                            "name": "TransPay MFB",
                            "bankCode": "181",
                            "nipCode": "090708",
                            "active": true,
                            "abcSortCode": "181150000",
                            "isOfi": false
                        },
                        {
                            "name": "Focus MFB",
                            "bankCode": "182",
                            "nipCode": "090709",
                            "active": true,
                            "abcSortCode": "182150000",
                            "isOfi": false
                        },
                        {
                            "name": "UCEE MFB",
                            "bankCode": "180",
                            "nipCode": "090706",
                            "active": true,
                            "abcSortCode": "180150000",
                            "isOfi": false
                        },
                        {
                            "name": "Daily Trust MFB",
                            "bankCode": "179",
                            "nipCode": "090705",
                            "active": true,
                            "abcSortCode": "179150000",
                            "isOfi": false
                        },
                        {
                            "name": "Simplify Synergy",
                            "bankCode": "177",
                            "nipCode": "110034",
                            "active": true,
                            "abcSortCode": "177150000",
                            "isOfi": false
                        },
                        {
                            "name": "Umyu MFB",
                            "bankCode": "176",
                            "nipCode": "090704",
                            "active": true,
                            "abcSortCode": "176150000",
                            "isOfi": false
                        },
                        {
                            "name": "Bokkos MFB",
                            "bankCode": "173",
                            "nipCode": "090703",
                            "active": true,
                            "abcSortCode": "173150000",
                            "isOfi": false
                        },
                        {
                            "name": "Olive MFB",
                            "bankCode": "172",
                            "nipCode": "090696",
                            "active": true,
                            "abcSortCode": "172150000",
                            "isOfi": false
                        },
                        {
                            "name": "WRA MFB",
                            "bankCode": "167",
                            "nipCode": "090631",
                            "active": true,
                            "abcSortCode": "167150000",
                            "isOfi": false
                        },
                        {
                            "name": "Omak MFB",
                            "bankCode": "169",
                            "nipCode": "090700",
                            "active": true,
                            "abcSortCode": "169150000",
                            "isOfi": false
                        },
                        {
                            "name": "Akalabo MFB",
                            "bankCode": "171",
                            "nipCode": "090698",
                            "active": true,
                            "abcSortCode": "171150000",
                            "isOfi": false
                        },
                        {
                            "name": "Wesley MFB",
                            "bankCode": "170",
                            "nipCode": "090699",
                            "active": true,
                            "abcSortCode": "170150000",
                            "isOfi": false
                        },
                        {
                            "name": "IBBU MFB",
                            "bankCode": "168",
                            "nipCode": "090697",
                            "active": true,
                            "abcSortCode": "168150000",
                            "isOfi": false
                        },
                        {
                            "name": "Awe MFB",
                            "bankCode": "164",
                            "nipCode": "090693",
                            "active": true,
                            "abcSortCode": "164150000",
                            "isOfi": false
                        },
                        {
                            "name": "EJINDU MFB",
                            "bankCode": "163",
                            "nipCode": "090694",
                            "active": true,
                            "abcSortCode": "163150000",
                            "isOfi": false
                        },
                        {
                            "name": "Credit Direct Limited",
                            "bankCode": "165",
                            "nipCode": "110049",
                            "active": true,
                            "abcSortCode": "165150000",
                            "isOfi": false
                        },
                        {
                            "name": "Garun Mallam MFB",
                            "bankCode": "162",
                            "nipCode": "090691",
                            "active": true,
                            "abcSortCode": "162150000",
                            "isOfi": false
                        },
                        {
                            "name": "MONEYTRONICS MFB",
                            "bankCode": "161",
                            "nipCode": "090692",
                            "active": true,
                            "abcSortCode": "161150000",
                            "isOfi": false
                        },
                        {
                            "name": "Ure MFB",
                            "bankCode": "160",
                            "nipCode": "090619",
                            "active": true,
                            "abcSortCode": "160150000",
                            "isOfi": false
                        },
                        {
                            "name": "Prudent MFB",
                            "bankCode": "159",
                            "nipCode": "090690",
                            "active": true,
                            "abcSortCode": "159150000",
                            "isOfi": false
                        },
                        {
                            "name": "Unlimint Nigeria Limited",
                            "bankCode": "158",
                            "nipCode": "110081",
                            "active": true,
                            "abcSortCode": "158150000",
                            "isOfi": false
                        },
                        {
                            "name": "Gosifechukwu MFB",
                            "bankCode": "155",
                            "nipCode": "090687",
                            "active": true,
                            "abcSortCode": "155150000",
                            "isOfi": false
                        },
                        {
                            "name": "CSD MFB",
                            "bankCode": "156",
                            "nipCode": "090686",
                            "active": true,
                            "abcSortCode": "156150000",
                            "isOfi": false
                        },
                        {
                            "name": "Prospects MFB",
                            "bankCode": "157",
                            "nipCode": "090689",
                            "active": true,
                            "abcSortCode": "157150000",
                            "isOfi": false
                        },
                        {
                            "name": "Mutual Alliance Mortgage Bank",
                            "bankCode": "153",
                            "nipCode": "070028",
                            "active": true,
                            "abcSortCode": "153150000",
                            "isOfi": false
                        },
                        {
                            "name": "Katagum MFB",
                            "bankCode": "154",
                            "nipCode": "090684",
                            "active": true,
                            "abcSortCode": "154150000",
                            "isOfi": false
                        },
                        {
                            "name": "BELLBANK MFB",
                            "bankCode": "151",
                            "nipCode": "090672",
                            "active": true,
                            "abcSortCode": "151150000",
                            "isOfi": false
                        },
                        {
                            "name": "ROYAL BLUE MFB",
                            "bankCode": "355",
                            "nipCode": "090622",
                            "active": true,
                            "abcSortCode": "355150000",
                            "isOfi": false
                        },
                        {
                            "name": "NET MFB",
                            "bankCode": "152",
                            "nipCode": "090675",
                            "active": true,
                            "abcSortCode": "152150000",
                            "isOfi": false
                        },
                        {
                            "name": "M&M MFB",
                            "bankCode": "150",
                            "nipCode": "090685",
                            "active": true,
                            "abcSortCode": "150150000",
                            "isOfi": false
                        },
                        {
                            "name": "Palmera MFB",
                            "bankCode": "147",
                            "nipCode": "090653",
                            "active": true,
                            "abcSortCode": "147150000",
                            "isOfi": false
                        },
                        {
                            "name": "XPRESS MTS",
                            "bankCode": "148",
                            "nipCode": "100041",
                            "active": true,
                            "abcSortCode": "148150000",
                            "isOfi": false
                        },
                        {
                            "name": "KAYI MFB",
                            "bankCode": "146",
                            "nipCode": "090667",
                            "active": true,
                            "abcSortCode": "146150000",
                            "isOfi": false
                        },
                        {
                            "name": "BETHEL MFB",
                            "bankCode": "145",
                            "nipCode": "090683",
                            "active": true,
                            "abcSortCode": "145150000",
                            "isOfi": false
                        },
                        {
                            "name": "BUYPOWER MFB",
                            "bankCode": "144",
                            "nipCode": "090682",
                            "active": true,
                            "abcSortCode": "144150000",
                            "isOfi": false
                        },
                        {
                            "name": "AAA FINANCE",
                            "bankCode": "465",
                            "nipCode": "050005",
                            "active": true,
                            "abcSortCode": "465150000",
                            "isOfi": false
                        },
                        {
                            "name": "AMOYE MFB",
                            "bankCode": "340",
                            "nipCode": "090610",
                            "active": true,
                            "abcSortCode": "340150000",
                            "isOfi": false
                        },
                        {
                            "name": "BLUEPRINT INVESTMENTS MFB",
                            "bankCode": "981",
                            "nipCode": "090538",
                            "active": true,
                            "abcSortCode": "981150000",
                            "isOfi": false
                        },
                        {
                            "name": "BOROMU MFB",
                            "bankCode": "954",
                            "nipCode": "090501",
                            "active": true,
                            "abcSortCode": "954150000",
                            "isOfi": false
                        },
                        {
                            "name": "CANAAN MFB",
                            "bankCode": "390",
                            "nipCode": "090647",
                            "active": true,
                            "abcSortCode": "390150000",
                            "isOfi": false
                        },
                        {
                            "name": "CASHRITE MFB",
                            "bankCode": "398",
                            "nipCode": "090649",
                            "active": true,
                            "abcSortCode": "398150000",
                            "isOfi": false
                        },
                        {
                            "name": "CATLAND MFB",
                            "bankCode": "359",
                            "nipCode": "090498",
                            "active": true,
                            "abcSortCode": "359150000",
                            "isOfi": false
                        },
                        {
                            "name": "DIGNITY FINANCE",
                            "bankCode": "342",
                            "nipCode": "050013",
                            "active": true,
                            "abcSortCode": "342150000",
                            "isOfi": false
                        },
                        {
                            "name": "EXCEL MFB",
                            "bankCode": "141",
                            "nipCode": "090678",
                            "active": true,
                            "abcSortCode": "141150000",
                            "isOfi": false
                        },
                        {
                            "name": "FUNDQUEST FINANCIAL SERVICES",
                            "bankCode": "495",
                            "nipCode": "050010",
                            "active": true,
                            "abcSortCode": "495150000",
                            "isOfi": false
                        },
                        {
                            "name": "IBU-AJE MFB",
                            "bankCode": "976",
                            "nipCode": "090488",
                            "active": true,
                            "abcSortCode": "976150000",
                            "isOfi": false
                        },
                        {
                            "name": "IMSU MFB",
                            "bankCode": "129",
                            "nipCode": "090670",
                            "active": true,
                            "abcSortCode": "129150000",
                            "isOfi": false
                        },
                        {
                            "name": "IPERU MFB",
                            "bankCode": "913",
                            "nipCode": "090493",
                            "active": true,
                            "abcSortCode": "913150000",
                            "isOfi": false
                        },
                        {
                            "name": "LEADCITY MFB",
                            "bankCode": "397",
                            "nipCode": "090650",
                            "active": true,
                            "abcSortCode": "397150000",
                            "isOfi": false
                        },
                        {
                            "name": "LOMA MFB",
                            "bankCode": "351",
                            "nipCode": "090620",
                            "active": true,
                            "abcSortCode": "351150000",
                            "isOfi": false
                        },
                        {
                            "name": "MAB ALLIANZ MFB",
                            "bankCode": "354",
                            "nipCode": "090623",
                            "active": true,
                            "abcSortCode": "354150000",
                            "isOfi": false
                        },
                        {
                            "name": "MKOBO MFB",
                            "bankCode": "492",
                            "nipCode": "090455",
                            "active": true,
                            "abcSortCode": "492150000",
                            "isOfi": false
                        },
                        {
                            "name": "ODOAKPU MFB",
                            "bankCode": "112",
                            "nipCode": "090654",
                            "active": true,
                            "abcSortCode": "112150000",
                            "isOfi": false
                        },
                        {
                            "name": "OHHA MFB",
                            "bankCode": "370",
                            "nipCode": "090626",
                            "active": true,
                            "abcSortCode": "370150000",
                            "isOfi": false
                        },
                        {
                            "name": "OJOKORO MFB",
                            "bankCode": "965",
                            "nipCode": "090527",
                            "active": true,
                            "abcSortCode": "965150000",
                            "isOfi": false
                        },
                        {
                            "name": "PYRAMID MFB",
                            "bankCode": "115",
                            "nipCode": "090657",
                            "active": true,
                            "abcSortCode": "115150000",
                            "isOfi": false
                        },
                        {
                            "name": "REHOBOTH MFB",
                            "bankCode": "872",
                            "nipCode": "090463",
                            "active": true,
                            "abcSortCode": "872150000",
                            "isOfi": false
                        },
                        {
                            "name": "REVELATION MFB",
                            "bankCode": "126",
                            "nipCode": "090666",
                            "active": true,
                            "abcSortCode": "126150000",
                            "isOfi": false
                        },
                        {
                            "name": "SHONGOM MFB",
                            "bankCode": "451",
                            "nipCode": "090558",
                            "active": true,
                            "abcSortCode": "451150000",
                            "isOfi": false
                        },
                        {
                            "name": "SOURCE MFB",
                            "bankCode": "388",
                            "nipCode": "090641",
                            "active": true,
                            "abcSortCode": "388150000",
                            "isOfi": false
                        },
                        {
                            "name": "TEKLA FINANCE",
                            "bankCode": "488",
                            "nipCode": "050007",
                            "active": true,
                            "abcSortCode": "488150000",
                            "isOfi": false
                        },
                        {
                            "name": "THE MILLENNIUM MFB",
                            "bankCode": "183",
                            "nipCode": "090711",
                            "active": true,
                            "abcSortCode": "183150000",
                            "isOfi": false
                        },
                        {
                            "name": "LUKEFIELD FINANCE COMPANY",
                            "bankCode": "358",
                            "nipCode": "050015",
                            "active": true,
                            "abcSortCode": "358150000",
                            "isOfi": false
                        },
                        {
                            "name": "UNUBI MFB",
                            "bankCode": "190",
                            "nipCode": "090719",
                            "active": true,
                            "abcSortCode": "190150000",
                            "isOfi": false
                        },
                        {
                            "name": "VALE FINANCE",
                            "bankCode": "114",
                            "nipCode": "050020",
                            "active": true,
                            "abcSortCode": "114150000",
                            "isOfi": false
                        },
                        {
                            "name": "FAIRMONEY MFB",
                            "bankCode": "440",
                            "nipCode": "090551",
                            "active": true,
                            "abcSortCode": "440150000",
                            "isOfi": false
                        },
                        {
                            "name": "ALVANA MFB",
                            "bankCode": "908",
                            "nipCode": "090489",
                            "active": true,
                            "abcSortCode": "908150000",
                            "isOfi": false
                        },
                        {
                            "name": "CEDAR MFB",
                            "bankCode": "454",
                            "nipCode": "090562",
                            "active": true,
                            "abcSortCode": "454150000",
                            "isOfi": false
                        },
                        {
                            "name": "ZIKORA MFB",
                            "bankCode": "924",
                            "nipCode": "090504",
                            "active": true,
                            "abcSortCode": "924150000",
                            "isOfi": false
                        },
                        {
                            "name": "AKWA SAVINGS AND LOANS",
                            "bankCode": "972",
                            "nipCode": "070025",
                            "active": true,
                            "abcSortCode": "972150000",
                            "isOfi": false
                        },
                        {
                            "name": "ARAMOKO MFB",
                            "bankCode": "977",
                            "nipCode": "090307",
                            "active": true,
                            "abcSortCode": "977150000",
                            "isOfi": false
                        },
                        {
                            "name": "ABULESORO MFB",
                            "bankCode": "995",
                            "nipCode": "090545",
                            "active": true,
                            "abcSortCode": "995150000",
                            "isOfi": false
                        },
                        {
                            "name": "Rima Growth pathway MFB",
                            "bankCode": "938",
                            "nipCode": "090515",
                            "active": true,
                            "abcSortCode": "938150000",
                            "isOfi": false
                        },
                        {
                            "name": "MOMO PSB",
                            "bankCode": "940",
                            "nipCode": "120003",
                            "active": true,
                            "abcSortCode": "940150000",
                            "isOfi": false
                        },
                        {
                            "name": "NEWEDGE FINANCE",
                            "bankCode": "459",
                            "nipCode": "050004",
                            "active": true,
                            "abcSortCode": "459150000",
                            "isOfi": false
                        },
                        {
                            "name": "BRANCH INTL FINANCIAL SERVICES",
                            "bankCode": "468",
                            "nipCode": "050006",
                            "active": true,
                            "abcSortCode": "468150000",
                            "isOfi": false
                        },
                        {
                            "name": "OCTOPUS MFB",
                            "bankCode": "475",
                            "nipCode": "090576",
                            "active": true,
                            "abcSortCode": "475150000",
                            "isOfi": false
                        },
                        {
                            "name": "EWT MFB",
                            "bankCode": "477",
                            "nipCode": "090572",
                            "active": true,
                            "abcSortCode": "477150000",
                            "isOfi": false
                        },
                        {
                            "name": "ISLAND MFB",
                            "bankCode": "483",
                            "nipCode": "090584",
                            "active": true,
                            "abcSortCode": "483150000",
                            "isOfi": false
                        },
                        {
                            "name": "Parkway MFB",
                            "bankCode": "860",
                            "nipCode": "090390",
                            "active": true,
                            "abcSortCode": "860150000",
                            "isOfi": false
                        },
                        {
                            "name": "OLUYOLE MFB",
                            "bankCode": "868",
                            "nipCode": "090460",
                            "active": true,
                            "abcSortCode": "868150000",
                            "isOfi": false
                        },
                        {
                            "name": "Monarch Microfinance Bank",
                            "bankCode": "871",
                            "nipCode": "090462",
                            "active": true,
                            "abcSortCode": "871150000",
                            "isOfi": false
                        },
                        {
                            "name": "Borstal Microfinance Bank",
                            "bankCode": "856",
                            "nipCode": "090454",
                            "active": true,
                            "abcSortCode": "856150000",
                            "isOfi": false
                        },
                        {
                            "name": "CoalCamp Microfinance Bank",
                            "bankCode": "873",
                            "nipCode": "090254",
                            "active": true,
                            "abcSortCode": "873150000",
                            "isOfi": false
                        },
                        {
                            "name": "MAINTRUST MFB",
                            "bankCode": "878",
                            "nipCode": "090465",
                            "active": true,
                            "abcSortCode": "878150000",
                            "isOfi": false
                        },
                        {
                            "name": "GOOD NEIGHBORS MFB",
                            "bankCode": "880",
                            "nipCode": "090467",
                            "active": true,
                            "abcSortCode": "880150000",
                            "isOfi": false
                        },
                        {
                            "name": "ASSETS MICROFINANCE BANK",
                            "bankCode": "885",
                            "nipCode": "090473",
                            "active": true,
                            "abcSortCode": "885150000",
                            "isOfi": false
                        },
                        {
                            "name": "Kolomoni MFB",
                            "bankCode": "899",
                            "nipCode": "090480",
                            "active": true,
                            "abcSortCode": "899150000",
                            "isOfi": false
                        },
                        {
                            "name": "Avuenegbe MFB",
                            "bankCode": "895",
                            "nipCode": "090478",
                            "active": true,
                            "abcSortCode": "895150000",
                            "isOfi": false
                        },
                        {
                            "name": "Chukwunenye  Microfinance Bank",
                            "bankCode": "909",
                            "nipCode": "090490",
                            "active": true,
                            "abcSortCode": "909150000",
                            "isOfi": false
                        },
                        {
                            "name": "First Heritage MFB",
                            "bankCode": "903",
                            "nipCode": "090479",
                            "active": true,
                            "abcSortCode": "903150000",
                            "isOfi": false
                        },
                        {
                            "name": "GBEDE MFB",
                            "bankCode": "478",
                            "nipCode": "090579",
                            "active": true,
                            "abcSortCode": "478150000",
                            "isOfi": false
                        },
                        {
                            "name": "Grant MF Bank",
                            "bankCode": "852",
                            "nipCode": "090335",
                            "active": true,
                            "abcSortCode": "852150000",
                            "isOfi": false
                        },
                        {
                            "name": "Ibom fadama Microfinance Bank",
                            "bankCode": "948",
                            "nipCode": "090519",
                            "active": true,
                            "abcSortCode": "948150000",
                            "isOfi": false
                        },
                        {
                            "name": "Isuofia MFB",
                            "bankCode": "970",
                            "nipCode": "090353",
                            "active": true,
                            "abcSortCode": "970150000",
                            "isOfi": false
                        },
                        {
                            "name": "Kingdom College  MFB",
                            "bankCode": "907",
                            "nipCode": "090487",
                            "active": true,
                            "abcSortCode": "907150000",
                            "isOfi": false
                        },
                        {
                            "name": "Nice Microfinance Bank",
                            "bankCode": "865",
                            "nipCode": "090459",
                            "active": true,
                            "abcSortCode": "865150000",
                            "isOfi": false
                        },
                        {
                            "name": "OLUCHUKWU MICROFINANCE BANK ",
                            "bankCode": "883",
                            "nipCode": "090471",
                            "active": true,
                            "abcSortCode": "883150000",
                            "isOfi": false
                        },
                        {
                            "name": "Oraukwu Microfinance Bank",
                            "bankCode": "910",
                            "nipCode": "090492",
                            "active": true,
                            "abcSortCode": "910150000",
                            "isOfi": false
                        },
                        {
                            "name": "SNOW MFB",
                            "bankCode": "473",
                            "nipCode": "090573",
                            "active": true,
                            "abcSortCode": "473150000",
                            "isOfi": false
                        },
                        {
                            "name": "TANADI MICROFINANCE BANK LTD",
                            "bankCode": "453",
                            "nipCode": "090560",
                            "active": true,
                            "abcSortCode": "453150000",
                            "isOfi": false
                        },
                        {
                            "name": "UniUyo Microfinance Bank",
                            "bankCode": "875",
                            "nipCode": "090338",
                            "active": true,
                            "abcSortCode": "875150000",
                            "isOfi": false
                        },
                        {
                            "name": "Uzondu MF Bank",
                            "bankCode": "857",
                            "nipCode": "090453",
                            "active": true,
                            "abcSortCode": "857150000",
                            "isOfi": false
                        },
                        {
                            "name": "Umuchinemere Procredit MFB",
                            "bankCode": "943",
                            "nipCode": "090514",
                            "active": true,
                            "abcSortCode": "943150000",
                            "isOfi": false
                        },
                        {
                            "name": "Triple A Microfinance bank",
                            "bankCode": "961",
                            "nipCode": "090525",
                            "active": true,
                            "abcSortCode": "961150000",
                            "isOfi": false
                        }
        ]);

        const selectedOption = ref(''); // Initialize selectedOption as empty

        const loading = ref(false);

        const isFormValid = computed(() => {
            return formData.value.verification_number && formData.value.account && formData.value.bank && selectedOption.value;
        });

        const handleSendOtp = async () => {
            loading.value = true;
            // console.log(verification_numb);
            try {
                // Example: Send form data to an API
                const apiEndpoint = `${process.env.VUE_APP_API_ENDPOINT}/auth/register`.replace(
                    /\s+/g,
                ""
                );
                // console.log('API Endpoint:', apiEndpoint);
                const response = await axios.post(apiEndpoint, {
                    verification_number: formData.value.verification_number,
                    account: formData.value.account,
                    bank: formData.value.bank ? formData.value.bank.name : '',
                    otpdeliverychannel: selectedOption.value,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.VUE_APP_API_KEY,
                    },
                });
                // console.log(response.data);
                if (response.data.code == 101 ) {
                    router.push({ name: 'ValidOtp' });
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message
                    });
                    localStorage.setItem('phone_number', response.data.data.phone_number);
                    localStorage.setItem('verification_number', formData.value.verification_number);
                } else if (response.data.code == 100) {
                    router.push({ name: 'ValidOtp' });
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message
                    });
                    localStorage.setItem('verification_number', formData.value.verification_number);
                    // console.log(response);
                    // toast.success(response.data.message);                   
                } else if (response.data.code == 102) {
                    router.push({ name: 'CreatePin' });
                    localStorage.setItem('verification_number', formData.value.verification_number);
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message
                    });
                    // console.log(formData.phone_number);
                    // toast.success(response.data.message);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.message
                    });
                    return;
                }
            } catch (error) {
                console.log(error);
                // toast.error(error.message);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.response.data.message
                });
            } finally {
                loading.value = false;
            }
        };

        const closePage = () => {
            localStorage.removeItem('verification_number');
            localStorage.removeItem('phone_number');
            localStorage.removeItem('email');

            router.push({ name: 'Home' }).then(() => {
                window.location.reload();
            });
        };

        return {
            banks,
            handleSendOtp,
            formData,
            closePage,
            selectedOption,
            isFormValid,
            savedPhoneNumber,
            savedEmail,
            loading,
            isPhoneDisabled,
            isEmailDisabled,
        };
    }
};
</script>

<style scoped>
.form-check-input+.form-check-label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #000407;
    border-radius: 3px;
    background-color: #fff;
}

.form-check-input:checked+.form-check-label::before {
    background-color: #007bff;
    border: 2px solid #007bff;
    content: "✔";
    color: #fff;
    text-align: center;
    line-height: 20px;
    font-size: 16px;
}

.form-check-label {
    margin-bottom: 0;
}

.form-check-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.form-check {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 30px;
}

.head_button {
    background-color: #093c72;
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 35px;
    color: white;
    cursor: pointer;
    border: none;
    position: absolute;
    top: 10px;
    right: 20px;
    /* border-radius: 80px; */
}

.login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: rgb(44, 153, 224);
    background: linear-gradient(90deg, rgba(44, 153, 224, 1) 0%, rgba(222, 222, 227, 1) 42%, rgba(242, 242, 242, 1) 100%);
}

.card {
    align-items: right;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    background-color: #e9e8e8;
    padding: 20px;
    /* position: absolute; */
    /* right: 40px; */
}

.form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.card-header {
    background-color: #e9e8e8;
    border-bottom: none;

}

.btn-primary:hover {
    background-color: #0056b3;
}

.bubeb-image {
    height: 100%;
    width: 90%;
}

.key-images {
    height: 100px;
    width: 90px;
    border-left: 160px;
}

.submit-button {
    width: 100%; /* Match the width of v-select */
    margin-top: 60px;
    background-color: blue;
        /* Default color for active state */
        color: white;
        /* Text color */
        border: 1px solid blue;
        /* Border color for active state */
}
/* Styles for the disabled state */
.submit-button:disabled {
    background-color: grey;
    /* Color when disabled */
    border: 1px solid grey;
    /* Border color when disabled */
    cursor: not-allowed;
    /* Change cursor to indicate disabled state */
    opacity: 0.6;
    /* Optionally, reduce opacity for a more subdued look */
}

/* Optional: Additional styles for hover and focus */
.submit-button:hover:enabled {
    background-color: darkblue;
    /* Darker blue for hover effect when enabled */
    border: 1px solid darkblue;
    /* Darker blue for border on hover */
}

@media (max-width: 540px) {
    .head_button {
        padding: 5px 10px;
        font-size: 15px;
    }
}
</style>
