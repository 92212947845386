<template>
    <div class="login-page">
        <header class="header">
            <div class="header-button">
                <button class="head_button" type="submit" @click="closePage">
                    <i class="bi bi-x-circle" @click="logout"> Close</i></button>
            </div>
        </header>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <img class="bubeb-image" src="../assets/images/benue_subeb.png">
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header text-center">
                            <img class="key-images" src="../assets/images/3dicons.png">
                            <div class="card-body">
                                <form @submit.prevent="submitOtp">
                                    <div class="form-group">
                                        <h2>We sent you an OTP</h2>
                                        <h5>We sent an OTP to your contact</h5>
                                    </div>
                                    <div class="otp-container">
                                        <input v-for="(digit, index) in otpValues" :key="index" ref="otpInputs"
                                            v-model="otpValues[index]" @input="handleInput(index, $event)"
                                            @keydown="handleBackspace(index, $event)" maxlength="1" class="otp-input"
                                            type="text" />
                                    </div>
                                    <div class="mb-3">
                                        <button type=" submit" class="btn btn-primary submit-button"
                                            :disabled="!allFieldsFilled || isSubmitting">
                                            <span v-if="isSubmitting" class="spinner-border spinner-border-sm"
                                                role="status" aria-hidden="true"></span>
                                            <span v-if="!isSubmitting">Proceed</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, nextTick, computed } from 'vue';
import Swal from 'sweetalert2';
import { useRouter } from 'vue-router';
import axios from 'axios';

export default {
    name: 'ValidOtp',

    setup() {
        const router = useRouter();
        const otpValues = reactive(Array(6).fill('')); // Assuming OTP is 6 digits
        const otpInputs = ref([]);
        const isSubmitting = ref(false);

        // Retrieve data from local storage
        const phone_number = localStorage.getItem('phone_number');
        const verification_number = localStorage.getItem('verification_number');

        // Computed property to format the phone number
        const formattedPhone_number = computed(() => {
            if (phone_number && phone_number.length >= 10) {
                const lastFourDigits = phone_number.slice(-4);
                return `....${lastFourDigits}`;
            }
            return '';
        });

        const handleInput = async (index, event) => {
            const value = event.target.value;
            otpValues[index] = value;

            // Ensure refs are updated and focus on the next input
            if (value.length === 1 && index < otpValues.length - 1) {
                await nextTick(); // Ensure DOM updates before focusing
                otpInputs.value[index + 1].focus();
            }
        };
        const handleBackspace = async (index, event) => {
            if (event.key === 'Backspace' && !otpValues[index] && index > 0) {
                otpInputs.value[index - 1].focus();
            }
        };

        const allFieldsFilled = computed(() => {
            return otpValues.every(value => value !== '');
        });

        const submitOtp = async () => {
            isSubmitting.value = true; // Disable button and show spinner
            const otpp = otpValues.join('');
            try {
                // console.log(otpp);
                // console.log(formData.verification_number);
                // console.log(formData);
                // Example: Send form data to an API
                const apiEndpoint = `${process.env.VUE_APP_API_ENDPOINT}/auth/verify_otp`.replace(
                    /\s+/g,
                    ""
                );
                // console.log('API Endpoint:', apiEndpoint);
                const response = await axios.post(apiEndpoint, {
                    verification_number: verification_number,
                    otp: otpp,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.VUE_APP_API_KEY,
                    },
                });
                // console.log(response.data.data);
                if (response.data.data !== '') {
                    // Assuming data structure has staffData
                    localStorage.setItem('staffData', JSON.stringify(response.data.data));
                    localStorage.setItem('verification_number', verification_number);
                    // store.verification_number = formData.verification_number; // Set the phone number in the formData
                    // console.log(response);
                    // toast.success(response.data.message);
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message
                    });

                    // Navigate to the next page
                    router.push({ name: 'StaffDetails' });
                } else {
                    console.error('No Staff Record Received');
                }
            } catch (error) {
                // console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.response.data.message
                });
                // toast.error(error.message);
            } finally {
                isSubmitting.value = false; // Re-enable button and hide spinner
            }

        };
        const closePage = () => {
            localStorage.removeItem('verification_number');
            localStorage.removeItem('phone_number');
            localStorage.removeItem('email');
            router.push({ name: 'Home' }).then(() => {
                window.location.reload();
            });
        };

        return {
            isSubmitting,
            router,
            formattedPhone_number,
            otpValues,
            handleInput,
            handleBackspace,
            submitOtp,
            otpInputs,
            allFieldsFilled,
            closePage,
        };
    }

};
</script>

<style scoped>
.head_button {
    background-color: #093c72;
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 35px;
    color: white;
    cursor: pointer;
    border: none;
    position: absolute;
    top: 10px;
    right: 20px;
}

.login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: rgb(44, 153, 224);
    background: linear-gradient(90deg, rgba(44, 153, 224, 1) 0%, rgba(222, 222, 227, 1) 42%, rgba(242, 242, 242, 1) 100%);
}

.card {
    align-items: right;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    background-color: #e9e8e8;
    padding: 20px;
}

.card-header {
    background-color: #e9e8e8;
    border-bottom: none;

}

.btn-primary {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}

h5 {
    color: #828DA9;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.bubeb-image {
    height: 100%;
    width: 90%;
}

.key-images {
    height: 100px;
    width: 90px;
    border-left: 160px;
}

.otp-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 50px;
}

.otp-input {
    width: 45px;
    height: 60px;
    border-radius: 40%;
    text-align: center;
    font-size: 18px;
    border: 2px solid #ccc;
    outline: none;
    transition: border-color 0.2s;
}

.otp-input:focus {
    border-color: #007bff;
}

.submit-button {
    width: 100%;
    /* Match the width of v-select */
    margin-top: 90px;
/* Default color for active state */
    color: white;
    /* Text color */
    border: 1px solid blue;
    /* Border color for active state */
}

/* Styles for the disabled state */
.submit-button:disabled {
    background-color: grey;
    /* Color when disabled */
    border: 1px solid grey;
    /* Border color when disabled */
    cursor: not-allowed;
    /* Change cursor to indicate disabled state */
    opacity: 0.6;
    /* Optionally, reduce opacity for a more subdued look */
}

/* Optional: Additional styles for hover and focus */
.submit-button:hover:enabled {
    background-color: darkblue;
    /* Darker blue for hover effect when enabled */
    border: 1px solid darkblue;
    /* Darker blue for border on hover */
}

@media (max-width: 540px) {
    .head_button {
        padding: 5px 10px;
        font-size: 15px;
    }
}
</style>
