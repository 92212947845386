// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";
import HomeP from "../components/HomeP.vue";
import StaffReg from "../components/StaffReg.vue";
import CreatePin from "../components/CreatePin.vue";
import StaffDetails from "../components/StaffDetails.vue";
import StaffLogin from "../components/StaffLogin.vue";
import ValidOtp from "../components/ValidOtp.vue";
import GeneratePayslip from "../components/GeneratePayslip.vue";
import PayslipPage from "../components/PayslipPage.vue";
import OtpModal from "../components/OtpModal.vue";
import PayslipView from "../components/PayslipView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeP,
  },
  {
    path: "/StaffReg",
    name: "StaffReg",
    component: StaffReg,
  },
  {
    path: "/CreatePin",
    name: "CreatePin",
    component: CreatePin,
  },
  {
    path: "/StaffDetails",
    name: "StaffDetails",
    component: StaffDetails,
  },
  {
    path: "/Login",
    name: "StaffLogin",
    component: StaffLogin,
  },
  {
    path: "/ValidOtp",
    name: "ValidOtp",
    component: ValidOtp,
  },
  {
    path: "/GeneratePayslip",
    name: "GeneratePayslip",
    component: GeneratePayslip,
  },
  {
    path: "/Payslip",
    name: "PayslipPage",
    component: PayslipPage,
    props: (route) => ({ htmlContent: route.query.htmlContent }),
  },
  {
    path: "/OtpModal",
    name: "OtpModal",
    component: OtpModal,
  },
  {
    path: "/PayslipView/:refCode",
    name: "PayslipView",
    component: PayslipView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Retrieve the verification number from localStorage
  const verification_number = localStorage.getItem("verification_number");

  // Define the routes that require a verification number
  const requiresVerificationNumber = [
    "StaffReg",
    "StaffLogin",
    "ValidOtp",
    "OtpModal",
    "CreatePin",
    "StaffDetails",
    "GeneratePayslip",
  ];

  // Check if route requires verification number and it is missing
  if (requiresVerificationNumber.includes(to.name) && !verification_number) {
    next({ name: "Home" });
  } else {
    // Allow navigation if no conditions are met
    next();
  }
});

export default router;
