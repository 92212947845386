<template>
    <div class="staff-page">
        <header class="header">
            <div class="header-button">
                <button class="head_button" type="submit" @click="closePage">
                    <i class="bi bi-x-circle"> Close</i></button>
            </div>
        </header>
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <img class="bubeb-image" src="../assets/images/benue_subeb.png">
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="writeup">
                            <h4>YOUR DETAILS</h4>
                        </div>
                        <div class="card-header text-center" v-if="staffData">
                            <div class="profile-picture-container">
                                <img :src=ProfilePhoto alt="Profile photo" class="profile-photo">
                            </div>
                            <div class="profile-button-form-container">
                                <div class="profile-card">
                                    <div class="profile-info">
                                        <div class="profile-item">
                                            <span class="label">First Name</span>
                                            <span class="value">{{ staffData.firstName || 'N/A' }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-card">
                                    <div class="profile-info">
                                        <div class="profile-item">
                                            <span class="label">Last Name</span>
                                            <span class="value">{{ staffData.lastName || 'N/A' }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-card">
                                    <div class="profile-info">
                                        <div class="profile-item">
                                            <span class="label">Middle Name</span>
                                            <span class="value">{{ staffData.middleName || 'N/A' }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-card">
                                    <div class="profile-info">
                                        <div class="profile-item">
                                            <span class="label">Salary Bank</span>
                                            <span class="value">{{ staffData.bank || 'N/A' }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-card">
                                    <div class="profile-info">
                                        <div class="profile-item">
                                            <span class="label">Salary Account Number</span>
                                            <span class="value">{{ staffData.salaryaccount || 'N/A' }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-card">
                                    <div class="profile-info">
                                        <div class="profile-item">
                                            <span class="label">Institution Name</span>
                                            <span class="value">{{ staffData.institution || 'N/A' }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="md-3">
                            <button class="btn btn-primary submit-button" :disabled="isLoading" @click="handleSubmit()">
                                <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>
                                <span v-if="isLoading">Loading...</span>
                                <span v-else>Accept Details</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { useRouter } from 'vue-router';
import { ref, computed } from 'vue';

export default {
    name: 'StaffDetails',

    setup() {

        const staffData = ref({});
        const isLoading = ref(false);
        const router = useRouter();
        const foto = staffData.value.image;
        
        // Load staff data from localStorage
        const staffDataString = localStorage.getItem('staffData');
        if (staffDataString) {
            staffData.value = JSON.parse(staffDataString);
            // console.log('staffData:', staffData.value);
            // console.log(staffData.value.verificationNumber);
        }

        const ProfilePhoto = computed(() => {
            try {
                return require(foto);
            } catch (e) {
                return require('@/assets/images/avatar.png');
            }
        });

        // // Import default avatar using import.meta.glob
        // const defaultAvatar = import.meta.glob('@/assets/images/avatar.png');

        // // Computed property for ProfilePhoto
        // const ProfilePhoto = computed(() => {
        //     const imagePath = staffData.value.image;

        //     // Check if the imagePath is a valid URL or relative path
        //     if (imagePath) {
        //         // Return the image path from backend
        //         return imagePath;
        //     } else {
        //         // Fallback to default avatar image
        //         return defaultAvatar['@/assets/images/avatar.png'];
        //     }
        // });

        const handleSubmit = () => {

           
            if (!staffData.value?.verificationNumber) {
                return;
            }
            // const verification_number = staffData.value.verificationNumber;
            // console.log(verification_number);
            isLoading.value = true;
            // Simulate an API call with a timeout
            setTimeout(() => {
                // Set loading state to false after response is received
                isLoading.value = false;
                router.push('/CreatePin'); // Navigate to success page on successful submission
            }, 2000);
        };

        const closePage = () => {
            localStorage.removeItem('verification_number');
            localStorage.removeItem('phone_number');
            localStorage.removeItem('email');

            router.push({ name: 'Home' }).then(() => {
                window.location.reload();
            });
        };

        return {
            staffData,
            handleSubmit,
            closePage,
            ProfilePhoto,
        };

    }
};
</script>

<style scoped>
.head_button {
    background-color: #093c72;
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 35px;
    color: white;
    cursor: pointer;
    border: none;
    position: absolute;
    top: 10px;
    right: 20px;
}

.staff-page {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: rgb(44, 153, 224);
    background: linear-gradient(90deg, rgba(44, 153, 224, 1) 0%, rgba(222, 222, 227, 1) 42%, rgba(242, 242, 242, 1) 100%);
}

.card {
    align-items: right;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    background-color: #e9e8e8;
    padding-bottom: 10px;
    padding-left: 55px;
    padding-top: 5px;
}

.card-header {
    background-color: #e9e8e8;
    border-bottom: none;

}

.card-header {
    /* border-right: -70px; */
    width: 90%;
    border-radius: 14px;
    background: rgb(186, 228, 255);
    background: linear-gradient(90deg, rgba(186, 228, 255, 1) 0%, rgba(214, 214, 255, 1) 54%, rgba(240, 230, 224, 0.059839396284829705) 84%, rgba(255, 241, 223, 1) 91%, rgba(242, 242, 242, 1) 100%);
}

.writeup {
    text-align: center;
}

.card-body {
    background: rgb(186, 228, 255);
    background: linear-gradient(90deg, rgba(186, 228, 255, 1) 0%, rgba(205, 205, 227, 1) 54%, rgba(255, 241, 223, 1) 91%, rgba(242, 242, 242, 1) 100%);
}

.form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}



.btn-primary {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.bubeb-image {
    height: 70%;
    width: 89%;
    margin-top: 110px;

}

.profile-flex-form-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    display: flex;
    justify-content: center;
}

.profile-picture-container {
    display: flex;
    justify-content: center;
    max-width: 100vh;
    max-height: 100vh;
}

.profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 70%;
    /* Make the picture round */
    background-color: #949494;
}

.profile-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 19px;
    padding: 13px;
    margin-bottom: 5px;
    background: rgb(228, 229, 241);
    background: linear-gradient(90deg, rgba(228, 229, 241, 1) 0%, rgba(200, 233, 254, 0.6110819327731092) 40%, rgba(240, 230, 224, 0.059839396284829705) 65%, rgba(255, 241, 223, 1) 81%, rgba(255, 247, 247, 1) 97%);
}

.profile-info {
    display: flex;
    flex-direction: column;
}

.profile-item {
    display: flex;
    justify-content: space-between;
}

.label {

    color: dimgray;
}

.btn {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.value {
    font-weight: bold;
}

.submit-button {
    width: 90%;
    /* Match the width of v-select */
    margin-top: 50px;
    margin-left: -40px;
}

.md-3 {
    display: flex;
    justify-content: center;
    margin-top: -10px;
}

h4 {
    color: dimgray;
}

.profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    /* Make the picture round */
    margin-bottom: 15px;
}

@media (max-width: 540px) {
    .head_button {
        padding: 5px 10px;
        font-size: 15px;
    }
}
</style>
