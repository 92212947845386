<template>
  <div class="container">
    <div class="body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-1 section-left2"></div>
          <div class="col-md-10 section-center2">
            <img class="header-image" src="../assets/images/benue_subeb.png" />
            <p class="header-text">BENUE SUBEB</p>
            <button class="gene-button">+ Generate Payslip</button>
          </div>
          <div class="col-md-1 section-right2"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-1 section-left1"></div>
        <div class="col-md-5 section-left">
          <h1 class="ones">Staff Payslip Generator</h1>
          <br />
          <button class="dum-button">How to Use</button><br />
          <ul>
            <li>
              <b>Enter Staff Verification Number:</b> Input your unique staff
              VERIFICATION NUMBER in the provided field.
            </li>
            <li>
              <b>Enter Salary Bank Details:</b> Input your SALARY ACCOUNT NUMBER
              and select the BANK.
            </li>
            <li>
              <b>Registration:</b> Input the OTP you recieved through SMS or
              EMAIL, create PIN and then login with the PIN.
            </li>
            <li>
              <b>Generate Payslips:</b> Choose the desired YEAR and MONTH(S) for
              which you want to generate payslips using the dropdown menus.
            </li>
            <li>
              <b>Download Payslips:</b> You can Print or Download or Send via
              email the generated payslips; contact IT support if you encounter
              any issues.
            </li>
          </ul>
          <form class="form-inline" @submit.prevent="handleSubmit">
            <input
              type="text"
              class="form-control"
              id="verification_number"
              v-model="verification_number"
              placeholder="Enter Your Verification Number"
              required
            />
            <button class="go_button" type="submit" :disabled="loading">
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span v-else>GO</span>
            </button>
          </form>
        </div>
        <div class="col-md-1 section-right1"></div>
      </div>
    </div>
    <footer class="footer">
      <div class="footer-row">
        <img class="footer-image" src="../assets/images/benue_subeb.png" />
        <p class="footer-text">SUBEB</p>
        <!-- <div class="link">
                    <h5>Quick Link <br><a href="#">Generate Payslips</a></h5>
                </div> -->
        <div class="contact">
          <h5>Contacts</h5>
          <a href="https://benuesubeb.org">www.benuesubeb.org </a><br /><a
            >info@benuesubeb.org<br />(+234) 708 250 5052
          </a>
        </div>
        <div class="address">
          <h5>Address</h5>
          <a
            >#3 Ahmadu Bello Way, Old GRA Makurdi.<br />
            P.M.B 102070, Makurdi, Benue State.
          </a>
        </div>
      </div>
      <hr class="dotted-line" />
      <p>Copyright &copy;2024 Benue State Universal Basic Education Board</p>
    </footer>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import axios from "axios";
import { ref, onMounted } from "vue";

export default {
  name: "BenueFirst",

  setup() {
    const verification_number = ref("");
    const loading = ref(false);
    const router = useRouter();

    onMounted(() => {
      const savedVerificationNumber = localStorage.getItem(
        "verification_number"
      );
      const savedPhoneNumber = localStorage.getItem("phone_number");
      const savedEmail = localStorage.getItem("email");

      if (savedVerificationNumber) {
        verification_number.value = savedVerificationNumber;
      }
      // Example usage: pre-fill fields or use them as needed
      if (savedPhoneNumber) {
        // console.log("Phone number from local storage:", savedPhoneNumber);
        // You might want to use savedPhoneNumber in your component logic
      }

      if (savedEmail) {
        // console.log("Email from local storage:", savedEmail);
        // You might want to use savedEmail in your component logic
      }
    });
    // });

    const handleSubmit = async () => {
      loading.value = true;
      
      try {
        // Example: Send form data to an API"
        const apiEndpoint =
          `${process.env.VUE_APP_API_ENDPOINT}/auth/check_verification`.replace(
            /\s+/g,
            ""
          );
        // console.log("API Endpoint:", apiEndpoint);
        const response = await axios.post(
          apiEndpoint,
          { verification_number: verification_number.value },
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.VUE_APP_API_KEY,
            },
          }
        );
        if (response.data.code == 100) {
          router.push({ name: "StaffReg" }); // Navigate to success page on successful submission
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
          });
          localStorage.setItem("phone_number", response.data.phone);
          localStorage.setItem("email", response.data.email);
          localStorage.setItem(
            "verification_number",
            verification_number.value
          );
          // console.log(store.email);
        } else if (response.data.code == 102) {
          // console.log(response);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
          });

          return;
        } else if (response.data.code == 101) {
          router.push({ name: "StaffLogin" });
          Swal.fire({
            icon: "success",
            title: "success",
            text: response.data.message,
          });
          localStorage.setItem(
            "verification_number",
            verification_number.value
          );
        } else if (response.data.code == 103) {
          // console.log(response.data.phone);
          // console.log(verification_number.value);
          router.push({ name: "ValidOtp" });
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
          });
          localStorage.setItem(
            "verification_number",
            verification_number.value
          );
          localStorage.setItem("phone_number", response.data.phone);
        } else if (response.data.code == 104) {
          router.push({ name: "OtpModal" });
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
          });
          localStorage.setItem(
            "verification_number",
            verification_number.value
          );
        } else if (response.data.code == 105) {
          router.push({ name: "StaffReg" });
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
          });
          localStorage.setItem("email", response.data.email);
          localStorage.setItem(
            "verification_number",
            verification_number.value
          );
        } else if (response.data.code == 106) {
          router.push({ name: "StaffReg" });
          localStorage.setItem("phone_number", response.data.phone);
          localStorage.setItem(
            "verification_number",
            verification_number.value
          );
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
          });
          return;
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
        // toast.error(error.message);
        return;
      } finally {
        loading.value = false;
      }
    };
    return {
      verification_number,
      handleSubmit,
      loading,
    };
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.container {
  text-align: center;
  background-color: white;
  color: #002d72;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.body {
  background-image: url("@/assets/images/BG.png");
  background-size: 1700px;
  background-repeat: no-repeat;
  background-position: 70% 100%;
  width: 100%;
}

.header {
  padding-top: -80px;
}

.row {
  margin-top: 50px;
  padding-bottom: 0px;
  /* background-color: transparent; */
}

.ones {
  font-size: 60px;
  color: #034d7f;
  line-height: 64px;
}

.header-text {
  color: #034d7f;
  margin-top: -70px;
  font-size: 35px;
  height: 28px;
  margin-left: 90px;
  font-weight: bold;
  display: flex;
}

li {
  margin-top: 20px;
}

.gene-button {
  padding: 8px 10px;
  font-size: 18px;
  background-color: transparent;
  color: #034d7f;
  border: 2px solid #034d7f;
  /* Border color */
  border-radius: 35px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  justify-content: flex-end;
  margin-left: 800px;
  margin-top: -40px;
}

.gene-button:hover {
  background-color: #034d7f;
  color: #171718;
}

@media (max-width: 1166px) {
  .body {
    background-image: none;
  }
}

@media (max-width: 2890px) {
  .gene-button {
    padding: 8px 10px;
    font-size: 18px;
    background-color: transparent;
    color: #034d7f;
    order: 2px solid #034d7f;
    /* Border color */
    border-radius: 35px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* display: flex; */
    justify-content: flex-end;
    margin-left: 400px;
    margin-top: -40px;
    display: none;
  }

  .gene-button:hover {
    background-color: #034d7f;
    color: #171718;
  }
}

.dum-button {
  padding: 10px 10px;
  font-size: 20px;
  color: #056baf;
  border-radius: 35px;
  background-color: rgb(253, 244, 236);
  border: transparent;
  cursor: none;
}

.form-inline {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 30px;
}

.form {
  padding: 8px 90px;
  font-size: 20px;
  border-radius: 35px;
  color: #343535;
  background-color: white;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
}

.go_button {
  padding: 9px 30px;
  font-size: 20px;
  border-radius: 35px;
  color: white;
  cursor: pointer;
  background-color: #096fb3;
  border: none;
}

.go_button:hover {
  background-color: #0056b3;
}

.section-left {
  background-color: white;
  color: #2f3031;
  padding: 0px, 0px, 66.64px, 0px;
  height: Hug (632.33px);
  width: Fixed (619px);
  text-align: left;
  background-color: transparent;
}

.section-right {
  background-color: white;
  color: #007bff;
  margin-bottom: 20px;
  position: relative;
  padding: 0;
  background-color: transparent;
}

.section-button {
  background-color: #002d72;
  color: white;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: -130px;
}

.header-image {
  width: 72px;
  height: 72.32px;
  margin-right: 5000px;
  display: flex;
}

.main-content {
  padding: 20px;
}

.hero-image {
  width: 100%;
  height: 100%;
  height: auto;
  margin-top: 30px;
  object-fit: cover;
  padding-bottom: 0px;
}

.description {
  font-size: 1.2em;
  color: #002d72;
}

.footer {
  margin-top: 0px;
  background-color: #034d7f;
  color: white;
  padding: 0px;
  border: 0;
  width: 100%;
  padding-bottom: 0px;
}

.footer-text {
  font-size: 50px;
  margin-top: -70px;
  font-size: 35px;
  height: 28px;
  margin-left: 180px;
  font-weight: bold;
  display: flex;
}

.footer-image {
  width: 72px;
  height: 72.32px;
  margin-left: 100px;
  margin-top: 40px;
  display: flex;
}

/* .link {
    margin-top: -66px;
    text-align: left;
    margin-left: 900px;
} */

.contact {
  margin-top: -30px;
  text-align: left;
  margin-left: 800px;
}

.address {
  margin-top: -100px;
  text-align: center;
  margin-left: 1000px;
}

@media (max-width: 540px) {
  .contact {
    display: none;
  }

  .link {
    display: none;
  }

  .address {
    display: none;
  }

  .go_button {
    padding: 2px 19px;
    font-size: 20px;
    border-radius: 35px;
    color: white;
    cursor: pointer;
    background-color: #096fb3;
    border: none;
    font-size: 20px;
  }

  .go_button:hover {
    background-color: #0056b3;
  }
}

@media (max-width: 1890px) {
  .footer-image {
    width: 40px;
    height: 40.32px;
    margin-left: 20px;
    margin-top: 40px;
    display: flex;
  }

  .footer-text {
    font-size: 25px;
    margin-top: -40px;
    height: 18px;
    margin-left: 60px;
    font-weight: bold;
    display: flex;
  }

  /* .link {
        margin-top: 60px;
        text-align: left;
        margin-left: 0px;
        font-size: 17px;
    } */

  .contact {
    margin-top: 0px;
    text-align: left;
    margin-left: 250px;
  }

  .address {
    margin-top: -100px;
    text-align: center;
    margin-left: 450px;
  }
}

.col-md-3 {
  margin-top: -70px;
  text-align: left;
  margin-left: 1000px;
  display: flex;
}

.custom-col-2 {
  margin-top: -70px;
  text-align: right;
  justify-content: flex-end;
  margin-left: 400px;
  margin-left: 1200px;
}

.form-control {
  padding: 10px;
  border-radius: 40px;
  text-align: center;
  font-size: 20px;
  width: 70%;
}

@media (max-width: 1068px) {
  .form-control {
    font-size: 10px;
    /* Smaller font size for small screens */
  }
}

p {
  text-align: center;
  margin-top: 0px;
}

.dotted-line {
  border: none;
  border-top: 2px dotted #ccc;
  /* Add a dotted border */
  width: 100%;
  /* Make the line span the full width */
  margin-top: 55px;
  margin-bottom: 0px;
}
</style>
