<template>
    <div class="login-page">
        <header class="header">
            <div class="header-button">
                <button class="head_button" type="button" @click="closePage">
                    <i class="bi bi-x-circle"> Close</i></button>
            </div>
        </header>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <img class="bubeb-image" src="../assets/images/benue_subeb.png">
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header text-center">
                            <!-- <div class="col-md-6"> -->
                            <img class="key-images" src="../assets/images/3dicons.png">
                            <!-- </div> -->
                            <div class="card-body">
                                <form @submit.prevent="handleSubmit">
                                    <div class="form-group">
                                        <h2>Log into this portal</h2>
                                        <h5>You will need this PIN anytime you wish to access this portal </h5>
                                    </div>
                                    <div class="pin-login-container">
                                        <div class="pin-inputs">
                                            <input v-for="(value, index) in pin" :key="'create' + index"
                                                :type="showPin ? 'text' : 'password'" maxlength="1" class="pin-input"
                                                v-model="pinDisplay[index]" @input="event => handleInput(event, index)"
                                                @keydown="event => handleKeyDown(event, index)"
                                                :ref="el => pinInputRefs[index] = el" />
                                        </div>
                                        <h5>Enter Your PIN</h5>

                                    </div>


                                </form>
                                <div class="mb-3">
                                    <button class="btn btn-primary submit-button" @click="submitPin"
                                        :disabled="!allFieldsFilled || isSubmitting">
                                        <span v-if="isSubmitting" class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        <span v-if="!isSubmitting">Proceed</span></button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { computed } from 'vue';
import { ref, nextTick } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';

export default {
    name: 'StaffLogin',
    setup() {
        const pin = ref(['', '', '', '']);
        const pinDisplay = ref(['', '', '', '']);
        const errorMessage = ref('');
        const showPin = ref(true);
        const pinInputRefs = ref([]);
        const router = useRouter();
        const isSubmitting = ref(false);  // New reactive property
        const verification_number = localStorage.getItem('verification_number') || '';


        const handleInput = async (event, index) => {
            const value = event.target.value;
            pin.value[index] = value;
            pinDisplay.value[index] = value;

            await nextTick();

            setTimeout(() => {
                pinDisplay.value[index] = '*';
            }, 500); // Show the input value for 500ms before masking it

            focusNext(index);
        };
        const handleKeyDown = (event, index) => {
            if (event.key === 'Backspace') {
                if (pinDisplay.value[index] === '') {
                    if (index > 0) {
                        pin.value[index - 1] = '';
                        pinDisplay.value[index - 1] = '';
                        pinInputRefs.value[index - 1].focus();
                    }
                } else {
                    pin.value[index] = '';
                    pinDisplay.value[index] = '';
                }
            }
        };
        const focusNext = (index) => {
            if (index < 3) {
                pinInputRefs.value[index + 1].focus();
            }
        };

        const allFieldsFilled = computed(() => {
            return pin.value.every(value => value !== '');
        });

        const submitPin = async () => {
            isSubmitting.value = true;  // Disable button and show spinner
            // Handle PIN submission logic here
            // console.log(verification_number);
            const Pin = pin.value.join('');
            try {
                const apiEndpoint = `${process.env.VUE_APP_API_ENDPOINT}/auth/login`.replace(
                    /\s+/g,
                ""
            );
                // console.log('API Endpoint:', apiEndpoint);
                const response = await axios.post(apiEndpoint, {
                    pin: Pin,
                    verification_number: verification_number,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.VUE_APP_API_KEY,
                    },
                });

                if (response.data.status !== 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message
                    });
                    
                    localStorage.setItem('verification_number', verification_number);
                    localStorage.setItem('staffData', JSON.stringify(response.data.data));

                    router.push({ name: 'GeneratePayslip' }); // Navigate to the next page
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.message
                    });
                    return;
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.response.data.message
                });
            } finally {
                isSubmitting.value = false;  // Re-enable button and hide spinner
            }
        }

        const closePage = () => {
            localStorage.removeItem('verification_number');
            localStorage.removeItem('phone_number');
            localStorage.removeItem('email');

            router.push({ name: 'Home' }).then(() => {
                window.location.reload();
            });
        };

        return {
            pin,
            pinDisplay,
            errorMessage,
            showPin,
            pinInputRefs,
            handleInput,
            handleKeyDown,
            focusNext,
            submitPin,
            closePage,
            allFieldsFilled,
            isSubmitting,

        };
    }
};
</script>

<style scoped>
.head_button {
    background-color: #093c72;
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 35px;
    color: white;
    cursor: pointer;
    border: none;
    position: absolute;
    top: 10px;
    right: 20px;
    /* border-radius: 80px; */
}

.login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: rgb(44, 153, 224);
    background: linear-gradient(90deg, rgba(44, 153, 224, 1) 0%, rgba(222, 222, 227, 1) 42%, rgba(242, 242, 242, 1) 100%);
}

.card {
    align-items: right;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    background-color: #e9e8e8;
    /* position: absolute; */
    /* right: 40px; */
}

.card-header {
    background-color: #e9e8e8;
    border-bottom: none;

}

.btn-primary {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.bubeb-image {
    height: 100%;
    width: 90%;
}

.key-images {
    height: 100px;
    width: 90px;
    border-left: 160px;
}

.pin-login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pin-inputs {
    display: flex;
    gap: 10px;
}

.pin-input {
    width: 45px;
    height: 60px;
    border-radius: 40%;
    text-align: center;
    border: 1px solid #ccc;
    font-size: 18px;
}

button {
    margin-top: 20px;
    border-radius: 40%;
}

p {
    color: red;
}

h5 {
    color: #8d8d8d;
}

.submit-button {
    width: 100%;
    /* Match the width of v-select */
    margin-top: 60px;
/* Default color for active state */
    color: white;
    /* Text color */
    border: 1px solid blue;
    /* Border color for active state */
}

/* Styles for the disabled state */
.submit-button:disabled {
    background-color: grey;
    /* Color when disabled */
    border: 1px solid grey;
    /* Border color when disabled */
    cursor: not-allowed;
    /* Change cursor to indicate disabled state */
    opacity: 0.6;
    /* Optionally, reduce opacity for a more subdued look */
}

/* Optional: Additional styles for hover and focus */
.submit-button:hover:enabled {
    background-color: darkblue;
    /* Darker blue for hover effect when enabled */
    border: 1px solid darkblue;
    /* Darker blue for border on hover */
}

@media (max-width: 540px) {
    .head_button {
        padding: 5px 10px;
        font-size: 15px;
    }
}
</style>
