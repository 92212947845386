import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import router from "./router";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
// import Toast, { POSITION } from "vue-toastification";
// import "vue-toastification/dist/index.css";
import store from "./store";
import "@fortawesome/fontawesome-free/css/all.css";

// const options = {
//   // You can set your default options here
//   position: POSITION.TOP_CENTER,
// };

const app = createApp(App);

app.component("v-select", vSelect);

app.use(store);

app.use(router);

// app.use(Toast, options);

app.mount("#app");
