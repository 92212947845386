<template>
    <div class="login-page">
        <header class="header">
            <div class="header-button">
                <button class="head_button" @click="logout"><i class="fas fa-sign-out-alt"></i>
                    Logout</button>
            </div>
        </header>
        <div class="container">
            <div class="row">
                <div class="col-md-6 left">
                    <img class="bubeb-image" src="../assets/images/benue_subeb.png">
                </div>
                <div class="col-md-6 right">
                    <div class="card">
                        <div class="card-header text-center">
                            <div class="form-group">
                                <h2>Hello</h2>
                                <h4>{{ data.isverification_exist.firstName }} {{ data.isverification_exist.lastName }}
                                </h4>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <h5>Select Payslip details below</h5>
                                    <h6>Use the fields provided below to select the year and month you wish to
                                        generate a pay slip for. <b>You can also select multiple months in a
                                            year at a time</b>.</h6>
                                </div>
                                <div class="year-month-selector container mt-4">
                                    <form @submit.prevent="generatePayslip">
                                        <div class="mb-3">
                                            <v-select :options="years" v-model="selectedYear"
                                                placeholder="Select Year"></v-select>
                                        </div>
                                        <div class="mb-3">
                                            <v-select :options="months" v-model="selectedMonths" multiple
                                                :closeOnSelect="false" placeholder="Select months"></v-select>
                                        </div>
                                        <div class="mb-3">
                                            <button type="submit" class="btn btn-primary submit-button"
                                                :disabled="!isFormValid || loading"><span v-if="loading"
                                                    class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                <span v-if="!loading">Generate Payslip</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';

export default {
    name: 'GeneratePayslip',
    components: { vSelect },
    setup() {
        const data = ref({});
        const router = useRouter();
        const verification_number = localStorage.getItem('verification_number') || '';
       
        const dataString = localStorage.getItem('staffData');
        if (dataString) {
            data.value = JSON.parse(dataString);
            // console.log('staffData:', data.value);
            // console.log(data.value.firstName);
        }

        const months = ref([
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ]);
        const selectedYear = ref('');
        const selectedMonths = ref([]);
        const loading = ref(false);

        const generateYears = (startYear, endYear) => {
            const years = [];
            for (let year = startYear; year <= endYear; year++) {
                years.push(year);
            }
            return years;
        };

        const years = computed(() => generateYears(2000, new Date().getFullYear()));

        const isFormValid = computed(() => {
            return selectedYear.value && selectedMonths.value.length > 0;
        });

        const logout = () => {
            localStorage.removeItem('verification_number');
            localStorage.removeItem('phone_number');
            localStorage.removeItem('email');
            localStorage.removeItem('access_token'); // Remove token from local storage

            router.push({ name: 'Home' });
        };

        // Auto logout after 30 minutes of inactivity
        let inactivityTimer = null;
        const resetInactivityTimer = () => {
            clearTimeout(inactivityTimer);
            inactivityTimer = setTimeout(() => {
                logout();
            }, 30 * 60 * 1000); // 30 minutes in milliseconds
        };

        // Set up event listeners for user interaction
        const setupInactivityLogout = () => {
            window.addEventListener('mousemove', resetInactivityTimer);
            window.addEventListener('keydown', resetInactivityTimer);
            window.addEventListener('click', resetInactivityTimer);
        };

        // Clean up event listeners on component unmount
        const cleanupInactivityLogout = () => {
            clearTimeout(inactivityTimer);
            window.removeEventListener('mousemove', resetInactivityTimer);
            window.removeEventListener('keydown', resetInactivityTimer);
            window.removeEventListener('click', resetInactivityTimer);
        };

        onMounted(() => {
            resetInactivityTimer(); // Start the timer when the component is mounted
            setupInactivityLogout(); // Listen for user activity
        });

        onBeforeUnmount(() => {
            cleanupInactivityLogout(); // Clean up when the component is destroyed
        });

        const generatePayslip = async () => {
            loading.value = true;
            try {
                const apiEndpoint = `${process.env.VUE_APP_API_ENDPOINT}/payslip/download`.replace(
                    /\s+/g,
                    ""
                );
                const response = await axios.post(apiEndpoint, {
                    year: selectedYear.value,
                    month: selectedMonths.value,
                    verification_number: verification_number,
                }, {
                    responseType: 'text', // Ensure response is treated as text for HTML
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.VUE_APP_API_KEY,
                        'authorization': data.value.access_token,
                        // Ensure the response is treated as a buffer
                    },
                });
                if (response.data) { 
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: "Payslip(s) Generated",
                    });
                    localStorage.setItem('verification_number', verification_number);
                    localStorage.setItem('year', selectedYear.value);
                    localStorage.setItem('month', selectedMonths.value);
                    // console.log(response.data);
                        router.push(`/Payslip?payslipData=${btoa(JSON.stringify(response.data)) }`)
                    
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: "No Payslip Record Found",
                    });
                    return;
                }
               
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: "No Payslip Record Found",
                });
            } finally {
                loading.value = false;
            }
        };
        return {
            months,
            selectedYear,
            selectedMonths,
            loading,
            years,
            generatePayslip,
            data,
            isFormValid,
            logout,
        };
    }
};
</script>

<style scoped>
@import "~vue-select/dist/vue-select.css";

.head_button {
    background-color: #093c72;
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 35px;
    color: white;
    cursor: pointer;
    border: none;
    position: absolute;
    top: 10px;
    right: 20px;
}

.login-page {
    display: flex;
        align-items: center;
        justify-content: center;
    overflow-y: scroll;
    height: 100vh;
    background: rgb(44, 153, 224);
    background: linear-gradient(90deg, rgba(44, 153, 224, 1) 0%, rgba(222, 222, 227, 1) 42%, rgba(242, 242, 242, 1) 100%);
}

.card {
    align-items: right;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    background-color: #e9e8e8;
    padding: 20px;
}

.card-header {
    background-color: #e9e8e8;
    border-bottom: none;
}
.btn-primary {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.bubeb-image {
    height: 100%;
    width: 90%;
}

.year-month-selector {
    max-width: 600px;
    margin: auto;
}

.dropdown-menu {
    max-height: 200px;
}

.dropdown-item {
    display: flex;
    align-items: center;
}

.form-check-input {
    margin-right: 10px;
}

button {
    margin-top: 20px;
}

p {
    color: red;
}

h2,
h5 {
    text-align: left;
}

h4 {
    color: #8d8d8d;
    text-align: left;
}

h6 {
    color: #8d8d8d;
    text-align: left;
}

v-select {
    text-align: center;
}

 .right {
    margin-top: 150px;
}

.left {
    margin-top: 100px;
}

.submit-button {
    width: 100%;
    /* Match the width of v-select */
    margin-top: 60px;
/* Default color for active state */
    color: white;
    /* Text color */
    border: 1px solid blue;
    /* Border color for active state */
}

/* Styles for the disabled state */
.submit-button:disabled {
    background-color: grey;
    /* Color when disabled */
    border: 1px solid grey;
    /* Border color when disabled */
    cursor: not-allowed;
    /* Change cursor to indicate disabled state */
    opacity: 0.6;
    /* Optionally, reduce opacity for a more subdued look */
}

/* Optional: Additional styles for hover and focus */
.submit-button:hover:enabled {
    background-color: darkblue;
    /* Darker blue for hover effect when enabled */
    border: 1px solid darkblue;
    /* Darker blue for border on hover */
}

.right {
    display: flex;
    justify-content: flex-end;
    /* Aligns the card to the right end */
    padding: 10px;
    /* Optional: Adds padding around the container */

}

.row{
    margin-top: 50px;
}

@media (max-width: 540px) {
    .head_button {
        padding: 5px 10px;
        font-size: 15px;
    }
}

.spinner-border {
    vertical-align: middle;
    margin-right: 5px;
}
</style>
