<template>
    <div class="container">
        <div class="body">
            <div class="container-fluid">
                <div v-if="loading" class="spinner-overlay">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="row1">
                    <div class="col-md-1 section-left2">
                    </div>
                    <div class="col-md-10 section-center2">
                        <div class="logo">
                            <img class="header-image" src="../assets/images/benue_subeb.png">
                            <p class="header-text">BENUE SUBEB</p>
                        </div>
                        <div class="button-group">
                            <button class="gene-button" @click="navigateToGeneratePayslip">+ Generate Another
                                Payslip</button>
                            <button class="log-button" @click="logout"><i class="fas fa-sign-out-alt"></i>
                                Logout</button>
                        </div>
                    </div>
                    <div class="row2">
                        <div class="button-container">
                            <button class="action-button" @click="shareViaEmail">
                                <i class="fas fa-envelope"></i> Share via Email
                            </button>
                            <button class="action-button" @click="printPdf">
                                <i class="fas fa-print"></i> Print
                            </button>
                            <button class="action-button" @click="downloadPdf">
                                <i class="fas fa-download"></i>
                            </button>
                        </div>
                    </div>
                    <div v-if="paginatedPayslips.length > 0" id="payslip-container" class="payslip-container">
                        <div v-for="(payslipGroup, pageIndex) in paginatedPayslips" :key="pageIndex">
                            <div v-if="currentPage === pageIndex + 1">
                                <div v-for="(payslip, index) in payslipGroup" :key="index" class="payslip">
                                    <div class="title">
                                        <p>{{ payslip.project }} PAYSLIP <span class="monthyear">{{ payslip.month }} {{
                                                payslip.year
                                                }}</span></p>
                                        <div class="ref"><span class="code">REF CODE </span> {{ payslip.refCode }}</div>
                                    </div>
                                    <div class="header">
                                        <div class="containe">
                                            <!-- Row 1 -->
                                            <div class="row row1">
                                                <!-- Left Section -->
                                                <div class="left-section">
                                                    <div class="profile-and-details">
                                                        <img :src=ProfilePhoto alt="Profile Picture"
                                                            class="profile-pic">
                                                        <div class="details">
                                                            <div class="detail-item"><span>NAMES</span></div>
                                                            <div class="detail-item"><span>GRADE
                                                                    LEVEL/STEP</span></div>
                                                            <div class="detail-item"><span>DESIGNATION</span>
                                                            </div>
                                                            <div class="detail-item"><span>MDA</span></div>
                                                            <div class="detail-item"><span>REMAINING DAYS IN
                                                                    SERVICE</span>
                                                            </div>
                                                        </div>
                                                        <div class="details2">
                                                            <div class="detail2-item">{{ payslip.lastName }} {{
                                                                payslip.firstName }} {{ payslip.middleName }}
                                                            </div>
                                                            <div class="detail2-item">{{ payslip.gradeLevelStep
                                                                }}</div>
                                                            <div class="detail2-item">{{ payslip.designation }}
                                                            </div>
                                                            <div class="detail2-item">{{ payslip.mda }}</div>
                                                            <div class="detail2-item">{{
                                                                payslip.remainingDaysInService
                                                                }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Right Section -->
                                                <div class="right-section">
                                                    <div class="qr-container">
                                                        <img :src="payslip.barcode" alt="QR Code" class="qr-code"
                                                            style="">
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Row 2 -->
                                            <div class="row row22">
                                                <!-- Left Section -->
                                                <div class="left-section">
                                                    <div class="profile-and-details">
                                                        <div class="bottom-details">
                                                            <div class="line"><span>DEPARTMENT</span></div>
                                                            <div class="line"><span>SALARY STRUCTURE</span>
                                                            </div>
                                                            <div class="line"><span>VERIFICATION NUMBER</span>
                                                            </div>
                                                        </div>
                                                        <div class="bottom-details2">
                                                            <div class="line2">{{ payslip.department }}</div>
                                                            <div class="line2">{{ payslip.salaryStructure }}
                                                            </div>
                                                            <div class="line2">{{ payslip.verificationNumber }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="link1">
                                                        Visit this link to verify your payslip
                                                        <strong style="color:black" :href="payslip.verificationLink">{{
                                                            payslip.link
                                                            }}</strong>
                                                    </div>
                                                </div>
                                                <!-- Right Section -->
                                                <div class="right-section">
                                                    <div class="profile-and-details">
                                                        <div class="bank-details">
                                                            <div class="detail-bank">BVN</div>
                                                            <div class="detail-bank">BANK</div>
                                                            <div class="detail-bank">ACCOUNT NUMBER</div>
                                                        </div>
                                                        <div class="bank-details2">
                                                            <div class="detail-bank2">{{ payslip.bvn }}</div>
                                                            <div class="detail-bank2">{{ payslip.salarybank }}
                                                            </div>
                                                            <div class="detail-bank2">{{ payslip.salaryaccount
                                                                }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="date">
                                                        <span class="head">Date of Retirement:</span> {{
                                                        payslip.retirement }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="info">
                                        <table>
                                            <tr class="table-header">
                                                <th>SUBJECT</th>
                                                <th>DESCRIPTION</th>
                                                <th>TOTAL</th>
                                            </tr>
                                            <tr>
                                                <td class="subject">BASIC SALARY</td>
                                                <td></td>
                                                <td>{{ payslip.basicSalary }}</td>
                                            </tr>
                                            <tr>
                                                <td class="subject">ALLOWANCES</td>
                                                <td>
                                                    <div class="horizontal-wrap">
                                                        <div v-for="(allowance, index) in payslip.allowances"
                                                            :key="index">
                                                            <div v-for="[key, value] in Object.entries(allowance)"
                                                                :key="key" class="inline-item">
                                                                <div v-if="key !== '_id'">
                                                                    {{ key }}: {{ value }}<br />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{ payslip.totalallowances }}</td>
                                            </tr>
                                            <tr>
                                                <td class="subject">MONTHLY GROSS</td>
                                                <td></td>
                                                <td>{{ payslip.monthlyGross }}</td>
                                            </tr>
                                            <tr>
                                                <td class="subject">DEDUCTIONS</td>
                                                <td>
                                                    <div class="horizontal-wrap">
                                                        <div v-for="(deduction, index) in payslip.deductions"
                                                            :key="index">
                                                            <div v-for="[key, value] in Object.entries(deduction)"
                                                                :key="key" class="inline-item">
                                                                <div v-if="key !== '_id'">
                                                                    {{ key }}: {{ value }}<br />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{ payslip.totaldeductions }}</td>
                                            </tr>
                                            <tr>
                                                <td class="subject">OVERPAYMENT</td>
                                                <td></td>
                                                <td>{{ payslip.overpayment }}</td>
                                            </tr>
                                            <tr>
                                                <td class="net-pay">NET PAY</td>
                                                <td></td>
                                                <td class="net-pay2">{{ payslip.netPay }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Pagination Controls -->
                    <div class="pagination-controls">
                        <!-- Previous button -->
                        <a href="#" @click.prevent="prevPage" :class="{ disabled: currentPage === 1 }">
                            &larr; Previous
                        </a>

                        <!-- Page numbers -->
                        <span v-for="page in totalPages" :key="page">
                            <a href="#" @click.prevent="goToPage(page)" :class="{ active: currentPage === page }">{{
                                page }}
                            </a>
                        </span>

                        <!-- Next button -->
                        <a href="#" @click.prevent="nextPage" :class="{ disabled: currentPage === totalPages }">
                            Next &rarr;
                        </a>
                    </div>


                </div>
            </div>
        </div>
    </div>
    <footer class="footer">
        <div class="footer-row">
            <img class="footer-image" src="../assets/images/benue_subeb.png">
            <p class="footer-text">SUBEB</p>
            <!-- <div class="link">
                <h5>Quick Link <br><a href="#">Generate Payslips</a></h5>
            </div> -->
            <div class="contact">
                <h5>Contacts</h5>
                <a href="https://benuesubeb.org">www.benuesubeb.org
                </a><br><a>info@benuesubeb.org<br>(+234) 708 250 5052 </a>
            </div>
            <div class="address">
                <h5>Address</h5>
                <a>#3 Ahmadu Bello Way, Old GRA Makurdi.<br>
                    P.M.B 102070, Makurdi, Benue State.
                </a>
            </div>
        </div>
        <hr class="dotted-line" />
        <p class="copyright">Copyright &copy;2024 Benue State Universal Basic Education Board</p>
    </footer>

    <div v-if="loading" class="spinner-overlay">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

</template>

<script>
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ref, computed, nextTick, onMounted, onBeforeUnmount } from 'vue';
import html2pdf from 'html2pdf.js';

export default ({
    name: 'PayslipPage',

    setup() {
        const route = useRoute();
        const data = ref({});
        const loading = ref(false);
        const router = useRouter();
        const verification_number = localStorage.getItem('verification_number') || '';
        const year = localStorage.getItem('year') || '';
        const month = localStorage.getItem('month') || '';
        const pdfUrl = ref(null); // Initialize pdfUrl as a reactive reference
        const encodedPayslipData = route.query.payslipData;
        const decodePayslipData = atob(encodedPayslipData);
        const payslipss = JSON.parse(JSON.parse(decodePayslipData));
        const firstname = (payslipss.data[0].firstName);
        const lastname = (payslipss.data[0].lastName);
        const filename = lastname + '_' + firstname + '_payslip.pdf';
        const foto = (payslipss.data[0].image);
        
        const currentPage = ref(1);
        const itemsPerPage = 2;

        const dataString = localStorage.getItem('staffData');
        if (dataString) {
            data.value = JSON.parse(dataString);
        }

        // Computed property to group payslips into pages
        const paginatedPayslips = computed(() => {
            const groupedPayslips = [];
            for (let i = 0; i < payslipss.data.length; i += itemsPerPage) {
                groupedPayslips.push(payslipss.data.slice(i, i + itemsPerPage));
            }
            
            return groupedPayslips;
        });

        // Compute the total number of pages
        const totalPages = computed(() => paginatedPayslips.value.length);

        // Methods to navigate between pages
        const nextPage = () => {
            if (currentPage.value < totalPages.value) {
                currentPage.value++;
            }
        };

        const prevPage = () => {
            if (currentPage.value > 1) {
                currentPage.value--;
            }
        };

        // Method to go to a specific page
        const goToPage = (page) => {
            if (page >= 1 && page <= totalPages.value) {
                currentPage.value = page;
            }
        };

        const ProfilePhoto = computed(() =>{
            try {
                return require(foto);
            } catch (e) {
                return require('@/assets/images/avatar.png');
            }
        });

        // console.log("pdfv:",pdfBlobUrl);
        const navigateToGeneratePayslip = () => {
            localStorage.setItem('verification_number', verification_number);
            router.push({ name: 'GeneratePayslip' });
        };

        const generatePdf = async () => {
            const combinedElement = document.createElement('div');
            for (let i = 0; i < totalPages.value; i++) {
                currentPage.value = i + 1;
                await nextTick(); // Wait for the DOM to update
                const element = document.getElementById('payslip-container');
                if (element) {
                    combinedElement.appendChild(element.cloneNode(true));
                } else {
                    console.error(`Element with id 'payslip-container' not found on page ${i + 1}`);
                }
            }

            const opt = {
                margin: [15, 1, 25, 4],
                filename: filename,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2, useCORS: true },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                pagebreak: { mode: ['css', 'legacy'] }
            };

            try {
                const pdfBlob = await html2pdf().from(combinedElement).set(opt).outputPdf('blob');
                return URL.createObjectURL(pdfBlob);
            } catch (error) {
                console.error('PDF generation error:', error);
                throw error;
            }
        };

        const shareViaEmail = async () => {
            try {
                const emailResult = await Swal.fire({
                    title: 'EMAIL SHARE',
                    input: 'email',
                    inputLabel: 'PDF Of The Generated Payslip Will Be Sent',
                    inputPlaceholder: 'Enter email address',
                    showCancelButton: true,
                    confirmButtonText: 'Send',
                    cancelButtonText: 'Cancel',
                    customClass: {
                        container: 'my-swal-container',
                        title: 'my-swal-title',
                        input: 'my-swal-input',
                        inputLabel: 'my-swal-input-label',
                        confirmButton: 'my-swal-confirm-button',
                        cancelButton: 'my-swal-cancel-button',
                        popup: 'my-swal-popup'
                    },
                    preConfirm: (email) => {
                        if (!email) {
                            Swal.showValidationMessage('Please enter an email address');
                        }
                        return email;
                    }
                });

                if (emailResult.isConfirmed) {
                    // Generate the PDF Blob directly
                    const blob = await fetch(await generatePdf()).then(r => r.blob());
                    const formData = new FormData();
                    formData.append('verificationNumber', verification_number);
                    formData.append('email', emailResult.value);
                    formData.append('file', blob, filename);

                    try {
                        loading.value = true;  // Show spinner
                        const apiEndpoint = `${process.env.VUE_APP_API_ENDPOINT}/payslip/mail_payslip`.replace(
                            /\s+/g,
                            ""
                        );
                        const response = await axios.post(apiEndpoint, formData, {
                            headers: {
                                'x-api-key': process.env.VUE_APP_API_KEY,
                                'authorization': data.value.access_token,
                            },
                        });

                        loading.value = false;  // Hide spinner

                        if (response.data.code == 101) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Email Sent',
                                text: response.data.message
                            });
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: response.data.message
                            });
                        }
                    } catch (error) {
                        loading.value = false;  // Hide spinner
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: `Failed to send email: ${error.message}`
                        });
                    }
                    finally {
                        loading.value = false;
                    }
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Unexpected error: ${error.message}`
                });
            }
            finally {
                loading.value = false;
            }
        };
      
        const downloadPdf = async () => {
            try {
                const pdfUrl = await generatePdf();
                const a = document.createElement('a');
                a.href = pdfUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                // Clean up by removing the anchor element
                document.body.removeChild(a);
            } catch (error) {
                console.error("Error during PDF download:", error);
            }
        };

        const printPdf = async () => {
            try {
                const pdfUrl = await generatePdf();
                const newWindow = window.open(pdfUrl);
                if (newWindow) {
                    newWindow.onload = () => {
                        newWindow.focus();
                        newWindow.print();
                    };
                } else {
                    console.error("Failed to open new window for printing");
                }
            } catch (error) {
                console.error("Error during PDF printing:", error);
            }
        };

        const logout = () => {
            localStorage.removeItem('verification_number');
            localStorage.removeItem('phone_number');
            localStorage.removeItem('email');
            localStorage.removeItem('access_token'); // Remove token from local storage

            router.push({ name: 'Home' });
        };

        // Auto logout after 30 minutes of inactivity
        let inactivityTimer = null;
        const resetInactivityTimer = () => {
            clearTimeout(inactivityTimer);
            inactivityTimer = setTimeout(() => {
                logout();
            }, 30 * 60 * 1000); // 30 minutes in milliseconds
        };

        // Set up event listeners for user interaction
        const setupInactivityLogout = () => {
            window.addEventListener('mousemove', resetInactivityTimer);
            window.addEventListener('keydown', resetInactivityTimer);
            window.addEventListener('click', resetInactivityTimer);
        };

        // Clean up event listeners on component unmount
        const cleanupInactivityLogout = () => {
            clearTimeout(inactivityTimer);
            window.removeEventListener('mousemove', resetInactivityTimer);
            window.removeEventListener('keydown', resetInactivityTimer);
            window.removeEventListener('click', resetInactivityTimer);
        };

        onMounted(() => {
            resetInactivityTimer(); // Start the timer when the component is mounted
            setupInactivityLogout(); // Listen for user activity
        });

        onBeforeUnmount(() => {
            cleanupInactivityLogout(); // Clean up when the component is destroyed
        });

        return {
            pdfUrl,
            navigateToGeneratePayslip,
            shareViaEmail,
            printPdf,
            downloadPdf,
            generatePdf,
            verification_number,
            year,
            month,
            logout,
            payslipss,
            currentPage,
            paginatedPayslips,
            totalPages,
            nextPage,
            prevPage,
            goToPage,
            ProfilePhoto,
            data,
            loading,
            
        };
    }
});
</script>

<style scoped>

.body {
    background-image: url('@/assets/images/LINES.png');
    background-repeat: no-repeat;
    background-position: 50% 90%;
    width: 100%;
    background-attachment: fixed;
}

.button-container {
    margin-bottom:  5px;
}

.action-button {
    background-color: transparent;
    padding: 10px 20px;
    margin: 0 10px;
    font-size: 16px;
    border: 2px solid #e4e4e4;
    border-radius: 5px;
    cursor: pointer;
    
}

.action-button:hover {
    background-color: #636363;
}

.action-button i {
    margin-right: 5px;
}

.container {
    background-color: #e9e8e8;
    /* color: #002D72; */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    max-width: 100%;
   
}

.header-text {
    color: #034D7F;
    margin-top: -70px;
    font-size: 35px;
    height: 28px;
    margin-left: -20px;
    font-weight: bold;
    display: flex;
}

li {
    margin-top: 40px;
}

.log-button {
    background-color: transparent;
    color: #077cca;
    border: 2px solid #077cca;
    border-radius: 35px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 0px;
}

.log-button:hover {
    background-color: #077cca;
    color: #171718
}

.gene-button {
    padding: 10px 20px;
    font-size: 50px;
    border-radius: 35px;
    border: none;
    margin-top: 0px;
    margin-right: 60px;
    background-color: transparent;
    color: #077cca;
    border: 2px solid #077cca;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.gene-button:hover {
    background-color: #077cca;
    color: #171718
}

@media (max-width: 440px) {
    .logo{
        margin-left: -270px;
        position: absolute;
        margin-top: -30px;
    }
    .button-group{
        padding: 10px 20px;
        font-size: 8px;
        border-radius: 35px;
        margin-right: -250px;
    }

    .gene-button{
        margin-right: 10px;
        
    }
    .log-button{
        border-radius: 15px;
        margin-right: 8px;
        margin-top: 5px;
    }
    .button-group {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}

@media (max-width: 2890px) {
    .button-group {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .log-button {
        padding: 13px 9px;
        font-size: 15px;
        /* background-color: transparent; */
        /* color: #077cca; */
        /* order: 2px solid #077cca; */
        border-radius: 35px;
        /* cursor: pointer; */
        transition: background-color 0.3s ease;
        justify-content: flex-end;
    }

    .gene-button:hover {
        background-color: #077cca;
        color: #171718
    }

    .gene-button {
        padding: 13px 9px;
        font-size: 15px;
        /* background-color: transparent; */
        /* color: #077cca; */
        /* border: 2px solid #077cca; */
        /* cursor: pointer; */
        /* transition: background-color 0.3s ease; */
        top: 70px;
        right: 0px;

    }

    .log-button:hover {
        background-color: #077cca;
        color: #171718
    }

    .action-button {
        background-color: transparent;
        padding: 10px 20px;
        margin: 0 10px;
        font-size: 16px;
        border: 2px solid #d2d2d2;
        border-radius: 5px;
        cursor: pointer;
    }

    .action-button i {
        margin-right: 5px;
    }
    
}
.header-image {
    width: 72px;
    height: 72.32px;
    margin-left: -100px;
    display: flex;
}

.main-content {
    padding: 20px;
}

.hero-image {
    width: 100%;
    height: 100%;
    height: auto;
    margin-top: 30px;
    object-fit: cover;
    padding-bottom: 0px;
}

.description {
    font-size: 1.2em;
    color: #002D72;
}

.footer {
    margin-top:0px;
    background-color: #034D7F;
    color: white;
    padding: 10px;
    border: 0;
    width: 100%;
    padding-bottom: 5px;
}

.footer-text {
    font-size: 50px;
    margin-top: -70px;
    font-size: 35px;
    height: 28px;
    margin-left: 180px;
    font-weight: bold;
    display: flex;

}

.footer-image {
    width: 72px;
    height: 72.32px;
    margin-left: 100px;
    margin-top: 10px;
    display: flex;
}

.contact {
    margin-top: -30px;
    text-align: left;
    margin-left: 800px;
}

.address {
    margin-top: -100px;
    text-align: center;
    margin-left: 1000px;
}

@media (max-width: 540px) {
    .contact {
        display: none;
    }

    .link {
        display: none;
    }

    .address {
        display: none;
    }

    .button-container{
        display: none;
    }

}

@media (max-width: 1890px) {
    .footer-image {
        width: 40px;
        height: 40.32px;
        margin-left: 20px;
        margin-top: 40px;
        display: flex;
    }

    .footer-text {
        font-size: 25px;
        margin-top: -40px;
        height: 18px;
        margin-left: 60px;
        font-weight: bold;
        display: flex;
    }

    .contact {
        margin-top: 0px;
        text-align: left;
        margin-left: 250px;
    }

    .address {
        margin-top: -100px;
        text-align: center;
        margin-left: 450px;
    }
}
.dotted-line {
    border: none;
    /* Remove default border */
    border-top: 2px dotted #ccc;
    /* Add a dotted border */
    width: 100%;
    /* Make the line span the full width */
    margin-top: 55px;
    margin-bottom: 0px;
}

.copyright{
    text-align: center;
    margin-top: 0px;
}

.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}
.my-swal-container {
    font-family: 'Arial', sans-serif;
    padding: 20px;
}

.my-swal-title {
    font-weight: bold;
}

.my-swal-input {
    font-size: 16px;
}

.my-swal-input-label {
    font-size: 14px;
}

.my-swal-confirm-button,
.my-swal-cancel-button {
    display: inline-block;
    /* Display buttons inline */
    width: auto;
    /* Make sure the width is auto */
    margin: 0 5px;
    /* Add some margin between buttons */
}

.my-swal-popup {
    padding: 20px;
}

/* Center buttons in the popup */
.my-swal-popup .swal2-actions {
    display: flex;
    /* Use flexbox for alignment */
    justify-content: center;
    /* Center align buttons horizontally */
    margin-top: 20px;
    /* Space between content and buttons */
}

.row1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    margin-top: 40px;
    margin-left: 40px;
    padding-bottom: 0px;
}
.row22 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    margin-top: 30px;
}

.payslip-container { 
    border: 1px solid white;
    background-color: white;
    padding: 8px;
    margin-top: 3px;
}

.payslip {
    margin: 10px auto;
    padding: 8px;
    border: 1px solid #5c5c5c;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-size: 10px;
    font-weight: bold;
    line-height: 12.55px;
    color: #034D7F;
    width: 700px;
    min-height: 405px;
    margin-top: 30px;
}

.title {
    margin-bottom: 5px;
    border-bottom: 1px solid #48484874;
    padding-bottom: 0px;
    text-align: left;
}

.title p {
    font-size: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: #484848;
}

.title .monthyear {
    font-weight: 60px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: black;
}

.ref {
    text-align: right;
    margin-top: -10px;
    color: #000000;
}

.code {
    color: #6464647e;
}

.header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0px;
    margin-bottom: 0px;
    gap: 10px;
}

.row {
    display: flex;
    padding: 10px 0;
    padding-top: 0px;
}

.row:last-child {
    border-bottom: none;
}

.left-section {
    width: 90%;
}

.right-section {
    width: 10%;
}

.qr-container {
    margin-top: -19px;
    display: flex;
    justify-content: flex-end;
}

.qr-code {
    border-radius: 4px;
    border: 1px solid #034D7F;
    width: 95px;
    height: 100px;
    padding: 5px;
    margin-top: -85px;
    margin-right: -290px;
}

.profile-and-details {
    display: flex;
    margin-top: -40px;
}


.profile-pic {
    border-radius: 4px;
    width: 85px;
    height: 100px;
    margin-top: 0px;
    margin-left: -75px;
    margin-right: 10px;
    border: 1px solid #7e7f7f70;
    padding: 4px;
    object-fit: cover;
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 5px;
}

.details2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #2c2b2bd7;
    background-color: rgba(234, 227, 219, 0.477);
    -webkit-print-color-adjust: exact;
        /* Ensures colors (including background images) are preserved during printing */
    print-color-adjust: exact;
        /* Same as above for non-WebKit browsers */
    
}

.details .detail-item {
    display: inline-block;
    border: 1px solid #034D7F;
    padding: 2px;
    border-radius: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
    background-color: white;
    padding-right: 5px;
}

.details2 .detail2-item {
    display: inline-block;
    border: 1px solid white;
    padding: 2px;
    border-radius: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
    padding-right: 225px;
}

.bottom-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 2px;
    margin-left: -4px;
    margin-top: 2px;
}

.bottom-details2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #2c2b2bd7;
    background-color: rgba(234, 227, 219, 0.477);
    -webkit-print-color-adjust: exact;
        /* Ensures colors (including background images) are preserved during printing */
    print-color-adjust: exact;
        /* Same as above for non-WebKit browsers */
    
}

.bottom-details .line {
    display: inline-block;
    border: 1px solid #034D7F;
    padding: 2px;
    border-radius: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
    background-color: white;
    padding-right: 0px;
}

.bottom-details2 .line2 {
    display: inline-block;
    border: 1px solid white;
    padding: 2px;
    border-radius: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
    padding-right: 40px;
}



.link1 {
    color: #6d6c6ca6;
    margin-top: 5px;
    margin-bottom: 0px;
    margin-left: -3px;
    display: inline; /* Ensures the content stays in line */
    white-space: nowrap; /* Prevents text from wrapping to a new line */
}

.bank-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 2px;
    margin-left: 45px;
    margin-top: 5px;
}

.bank-details2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #2c2b2bd7;
    background-color: rgba(234, 227, 219, 0.477);
    -webkit-print-color-adjust: exact; /* Ensures colors (including background images) are preserved during printing */
    print-color-adjust: exact; /* Same as above for non-WebKit browsers */
    
}

.row2{
    margin-right: -330px;
}

.bank-details .detail-bank {
    display: inline-block;
    border: 1px solid #034D7F;
    padding: 2px;
    border-radius: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
    background-color: white;
    padding-right: -5px;
}

.bank-details2 .detail-bank2 {
    display: inline-block;
    border: 1px solid white;
    padding: 2px;
    border-radius: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
    padding-right: 33px;
}

.date {
    text-align: right;
    color: black;
    margin-top: 3px;
    margin-bottom: 0px;
}

.date .head {
    color: #6d6c6ca6;
}

.row22 .left-section,
.row22 .right-section {
    width: 50%;

}

.row22 .left-section .detail-item,
.row22 .right-section .detail-item {
    margin-bottom: 0px;
}

.info {
    padding: 5px;
    margin-top: -13px;
    border-radius: 8px;
    padding: 8px;

}

.info table {
    width: 665px;
    height: 180px;
    border-collapse: collapse;
    margin: 0;
    

}

.info th,
.info td {
    padding: 2px;
    text-align: left;
    border: 2px solid #2c2b2bd7;
    /* border-radius: 4px; */
    color: #2c2b2bd7;
}

.info th {
    background-color: #000000;
    color: white;
    text-align: center;
}

.subject {
    background-color: #d5eaf8;
    color: black;
}

.info .net-pay {
    color: #f5f7f7;
    font-size: 12px;
    background-color: #0e0e0e;
}

.info .net-pay2 {
    color: #000000;
    font-size: 12px;
}

.horizontal-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    color: #6d6c6ca6;

}

.inline-item {
    display: inline-block;
    border: 1px solid #6d6c6ca6;
    padding: 2px;
    border-radius: 4px;
    white-space: nowrap;
    margin-right: 8px; /* Adds space to the right of each key-value pair */
    margin-bottom: 3px;
}

.table-header {
    text-align: center;
}

/* .dotted-line {
    border: none;
    border-top: 2px dotted #2e2d2d;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0px;
} */

.pagination-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    /* Space between elements */
    margin-top: 20px;
    /* Space above the controls */
    margin-bottom: 30px;
}

.pagination-controls a {
    color: #034D7F;
    /* Bootstrap primary color */
    text-decoration: none;
    font-size: 1rem;
    cursor: pointer;
    padding: 5px 10px;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 50%;
    display: inline-block;
}

.pagination-controls a:hover {
    background-color: #999b9c;
    /* Light background on hover */
    color: #034D7F;
    /* Darker blue on hover */
}

.pagination-controls .disabled {
    color: #6c757d;
    /* Bootstrap disabled color */
    cursor: not-allowed;
    pointer-events: none;
    /* Prevents click actions */
}

.pagination-controls span a.active {
    background-color: #034D7F;
    /* Blue background for active page */
    color: white;
    /* White text for active page */
    font-weight: bold;
    border-radius: 50%;
    /* Circular background */
    padding: 5px 10px;
}


@media (max-width: 860px) {
    .payslip-container {
        overflow-x: auto;
    }
    .container{
        overflow-x: auto;
    }

    .payslip {
        width: 700px;
        /* Fixed width */
        margin: 0 auto;
        /* Ensure horizontal scrolling */
        display: inline-block;
    }
    .action-button{
        pointer-events: none;
    }
    
}
</style>
